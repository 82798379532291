.edit-account-content {
	width: 600px;
	height: auto;

	.form-row-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		font-weight: 400;
	}

	.user-name {
		width: calc(60% - 10px);
		font-weight: 400;
	}

	.user-phone {
		width: calc(40% - 10px);
	}

	.user-email {
		width: calc(50% - 10px);
	}

	.edit-account-options {
		height: 80px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		span {
			text-decoration: underline;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
			cursor: pointer;
			margin-left: 5px;
		}

		.MuiSvgIcon-root{
			width: 18px;
			height: 18px;
		}
	}

	.edit-account-actions {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
	}
}

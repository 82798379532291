.form-item-content-line {
	width: calc(100% + 20px);
	height: 20px;
	margin-right: -20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;

	span {
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.21px;
		width: 25px;
	}

	.line {
		width: 100%;
		height: 2px;
		opacity: 0.5;
	}
}

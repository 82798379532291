.mobile-edit-account-content {
	width: 100%;
	height: auto;
	padding: 0px 20px 20px;

	.main-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		height: 60px;

		button {
			width: 58px;
			height: 25px;
			border: none;
			text-align: center;
			border-radius: 4px;
			text-align: center;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
		}

		.edit-button {
			width: 58px;
			height: 25px;
			border: none;
			text-align: center;
			border-radius: 4px;
			text-align: center;
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0.18px;
			border: 1px solid;
			padding: 1px 6px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.form-row-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.user-name {
		width: calc(50% - 10px);
	}

	.user-phone {
		width: calc(50% - 10px);
	}

	.user-email {
		width: 100%;
	}

	.edit-account-options {
		height: 80px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		span {
			text-decoration: underline;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
			cursor: pointer;
			margin-left: 5px;
		}
	}

	.edit-account-options-password {
		height: 80px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		span {
			text-decoration: underline;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
			cursor: pointer;
			margin-left: 5px;
		}

		.MuiSvgIcon-root{
			width: 18px;
			height: 18px;
		}
	}

	.edit-account-actions {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
	}
}

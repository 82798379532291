.side-bar-content {
	width: calc(50% - 80px);
	height: 100vh;
	position: absolute;
	z-index: 10;

	box-shadow: 5px 0px 10px #00000080;
	border-radius: 0px 35px 35px 0px;
	padding: 40px;

	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;

	.logo-pratica {
		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;

		img {
			width: 90%;
			max-width: 300px;
			height: auto;
		}
	}
}

@media screen and (max-device-width: 480px) {
	.side-bar-content {
		width: 100%;
		height: 100vh;
		position: relative;

		box-shadow: none;
		border-radius: 0px 0px;
		padding: 40px;

		.logo-pratica {
			img {
				width: 100%;
				max-width: 200px;
				height: auto;
				margin-bottom: 50px;
			}
		}
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.side-bar-content {
		width: 100%;
		height: 100vh;
		position: relative;

		box-shadow: none;
		border-radius: 0px 0px;
		padding: 40px;

		.logo-pratica {
			img {
				width: 90%;
				max-width: 300px;
				height: auto;
			}
		}
	}
}

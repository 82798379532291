.dropdown-content {
	width: 50px;
	height: 40px;
	padding: 0px 10px !important;
	border-radius: 6px;
	display: flex !important;
	align-items: center;
	justify-content: center;

	.MuiInput-underline,
	.MuiInput-underline:hover,
	.MuiInput-underline:hover:not(.Mui-disabled):before,
	.MuiInput-underline::before,
	.MuiInput-underline::after {
		border: none;
		outline: none;
		content: '' !important;
		font-size: 12px;
		letter-spacing: 0.18px;
		line-height: 16px !important;
	}

	.MuiSelect-icon {
		top: 1px;
		right: -2px;
	}
}

.MuiMenu-paper {
	width: auto;
	min-width: 120px;
	margin-top: 10px;
	margin-left: -5px;
	border-radius: 6px !important;
	box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.15) !important;
}

.MuiList-root.MuiMenu-list.MuiList-padding {
	padding: 0px;

	.MuiMenuItem-root {
		font-size: 12px !important;
		letter-spacing: 0.18px !important;
		line-height: 16px !important;
	}
}

@media screen and (max-width: 900px) {
	.dropdown-content {
		.MuiSelect-selectMenu {
			max-width: 35px;
		}
	}
}

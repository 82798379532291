.stores-settings-list-content {
	.stores-settings-list-header {
		display: flex;
		align-content: center;
		justify-content: flex-start;
		flex-direction: row;
		width: calc(100% - 30px);
		height: 30px;
		padding: 0px 20px;

		.list-header {
			display: flex;
			align-content: center;
			justify-content: flex-start;
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 0.2px;

			.MuiSvgIcon-root {
				width: 15px;
				height: 15px;
				margin-top: 2px;
				margin-left: 5px;
				cursor: pointer;
			}
		}

		.name,
		.full-name {
			width: 30%;
		}

		.equips {
			width: 15%;
		}

		.users {
			width: 25%;
		}
	}
}

@media screen and (max-width: 1300px) {
	.stores-settings-list-content {
		.stores-settings-list-header {
			.name,
			.full-name {
				width: 30%;
			}

			.equips {
				width: 15%;
			}

			.users {
				width: 25%;
			}
		}
	}
}

@media screen and (max-width: 900px) {
	.stores-settings-list-content {
		.stores-settings-list-header {
			display: none;
		}
	}
}

.equip-list-items {
	.list-item.web {
		width: 100%;
		height: 50px;
		padding: 0px 20px;
		display: flex;
		align-content: center;
		justify-content: space-between;
		flex-direction: row;
		cursor: pointer;

		.equip-info {
			width: calc(100% - 50px);
			display: flex;
			align-content: center;
			justify-content: flex-start;
			flex-direction: row;

			div {
				height: 100%;
				display: flex;
				align-items: center;
				text-align: left;
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.18px;
				text-transform: uppercase;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;

				span {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			.equip {
				width: 50%;
			}

			.warrant,
			.serial {
				width: 25%;
			}
		}

		.options {
			width: 50px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.options:hover {
			opacity: 0.8;
		}
	}

	.list-item.mobile {
		width: 100%;
		height: 70px;
		padding: 10px 20px;
		display: flex;
		align-content: center;
		justify-content: flex-start;
		flex-direction: row;
		cursor: pointer;

		.equip-info {
			width: calc(100% - 40px);
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-direction: column;

			.equip-info-row {
				width: 100%;
				padding-right: 25px;
				display: flex;
				align-content: center;
				justify-content: space-between;
				flex-direction: row;

				.equip {
					font-size: 13px;
					font-weight: 600;
					letter-spacing: 0.2px;
					text-transform: uppercase;

					span {
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}
				}

				.serial,
				.warrant {
					font-size: 12px;
					font-weight: 400;
					letter-spacing: 0.2px;
				}
			}

			.equip-info-row:first-child {
				height: 45px;
			}
		}

		.options {
			width: 40px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.mobile-label {
			margin-right: 5px;
		}
	}

	@media screen and (max-width: 1300px) {
		.list-item.web {
			.equip-info {
				.equip {
					width: 65%;
				}

				.warrant,
				.serial {
					width: 17.5%;
				}
			}
		}
	}

	// @media screen and (min-width: 901px) {
	// 	.list-item.mobile {
	// 		display: none;
	// 	}
	// }

	// @media screen and (max-width: 900px) {
	// 	.list-item.web {
	// 		display: none;
	// 	}
	// }

	/* For Mobiles */
	@media only screen and (max-device-width: 480px) {
		.list-item.mobile {
			height: 100px;

			.equip-info-row:first-child {
				height: 65px !important;
			}
		}
	}

	/* For Tablets */
	@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
		.list-item.mobile {
			height: 70px;
			align-content: flex-start;
			justify-content: space-between;

			.equip-info {
				height: 100%;

				.equip {
					height: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					span {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}

				.equip-info-row:first-child {
					height: 25px;
				}
			}
		}
	}
}

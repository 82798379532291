.create-address-content {
	width: 100%;
	height: auto;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;

	.zipCode {
		width: 20%;
	}

	.addressNumber,
	.stateSigla {
		width: 60px;
	}

	.city,
	.neighborhood {
		width: 200px;
	}

	.address,
	.complement {
		width: 270px;
	}

	.MuiFormGroup-root,
	.MuiFormControl-root {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
	}

	.MuiTypography-body1 {
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.18px;
		font-family: 'Roboto', sans-serif;
		opacity: 1;
	}

	.MuiIconButton-colorSecondary:hover {
		background-color: transparent !important;
	}
}

@media screen and (max-width: 900px) {
	.create-address-content {
		.zipCode {
			width: calc(40% - 20px);
		}

		.addressNumber {
			width: calc(30% - 20px);
		}

		.stateSigla {
			width: calc(20% - 20px);
		}

		.city {
			width: 60%;
		}

		.neighborhood {
			width: 80%;
		}

		.address {
			width: 100%;
		}

		.complement {
			width: 70%;
		}
	}
}

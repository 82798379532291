.equips-main-content {
	width: 100%;
	height: 100%;
	padding: 20px 0;

	.equips-header-content {
		width: 100%;
		height: 40px;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
	}
}

.equips-main-content.shadow-on-scroll {
	.equip-list-header {
		width: 100%;
		z-index: 10;
		position: relative;
		padding-right: 75px;
	}

	.equip-list-items {
		margin-top: 0px !important;
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.equips-main-content {
		padding: 0 0 20px;
	}

	.equips-main-content.shadow-on-scroll {
		.equips-header-content {
			height: 55px;
			padding-bottom: 15px;
			position: relative;
			z-index: 10;
		}

		.equip-list-content {
			margin-top: 0px !important;
		}

		.equip-list-items {
			margin-bottom: 55px !important;
		}
	}
}

@media only screen and (device-width: 768px) {
	/* default iPad screens */
	.equips-main-content {
		padding: 0 0 20px;
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */

	.equips-main-content {
		padding: 0;
	}

	.equips-main-content.shadow-on-scroll {
		.equips-header-content {
			height: 55px;
			padding-bottom: 15px;

			position: relative;
			z-index: 10;
		}

		.equip-list-content {
			margin-top: 0px !important;
		}
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
	/* For portrait layouts only */

	.equips-main-content {
		padding: 21px 0 19px;
	}
}

.paginator-main-content {
	width: 100%;
	margin: 20px 0 0;
	display: flex;
	align-items: center;
	justify-content: center;

	.MuiPaginationItem-page {
		font-size: 10px;
		font-weight: 500;
		letter-spacing: 0px;
		text-transform: uppercase;
	}

	li {
		width: 35px;
		height: 35px;
	}

	li:first-child,
	li:last-child {
		.MuiPaginationItem-page {
			background: none !important;
			opacity: 0.4;
		}
	}
}

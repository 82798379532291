.notifications-main-content {
	width: 100%;
	height: 100%;
	padding: 20px 0;
	.notifications-header-content {
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.title-page-area {
			padding: 0px 20px;
			height: 40px;
			display: flex;
			align-items: center;
			h4 {
				font-size: 24px;
				font-weight: 600;
				letter-spacing: 0.36px;
				text-transform: uppercase;
				margin: 0px;
			}
		}
		.options-list-area {
			width: 650px;
			height: 40px;
			padding: 0 12px 0 20px;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			.input-search-field {
				width: 410px;
				margin-right: 20px;
			}
			.dropdown-content {
				margin-right: 10px;
			}
			.notification-more-options {
				width: 40px;
				height: 40px;
				min-width: 40px !important;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				.MuiSvgIcon-root {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
	.notification-message-alert {
		padding-left: 20px;
		text-align: left;
		font-size: 10px;
		font-weight: 400;
		letter-spacing: 0.15px;
	}
	@media screen and (max-width: 970px) {
		.notifications-header-content {
			.options-list-area {
				width: 600px;
				.input-search-field {
					width: 320px;
					margin-right: 20px;
				}
			}
		}
	}
	@media screen and (max-width: 900px) {
		.notifications-header-content {
			.options-list-area {
				width: 100%;
				.input-search-field {
					width: calc(100% - 140px);
					margin-right: 20px;
				}
				.dropdown-content {
					.MuiSelect-selectMenu {
						width: 75px;
					}
				}
			}
		}
	}
}

#notifications-list-options {
	.MuiMenu-paper {
		width: 210px !important;
		text-align: right;
	}
	.MuiList-root {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
	}
	.MuiMenuItem-root {
		font-family: 'Roboto', sans-serif;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.18px;
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
	}
	.MuiTouchRipple-root {
		display: none;
	}
}

.notifications-main-content {
	.list-view {
		height: calc(100% - 40px);
		width: 100%;
		position: relative;
		.notifications-list-content {
			height: calc(100% - 85px);
			overflow-y: hidden;
			padding-top: 20px;
			margin-bottom: 10px;
			.notifications-list-items {
				height: calc(100% - 30px);
				overflow-y: auto;
			}
		}
	}
}

@media screen and (max-height: 1024px) and (max-width: 768px) {
	.notifications-main-content {
		padding: 0px;
		.list-view {
			margin-top: 20px;
			height: calc(100% - 130px);
			overflow-y: auto;
			.notifications-list-content {
				height: unset;
				overflow-y: unset;
				padding-top: 0px;
				.notifications-list-items {
					height: 100%;
					overflow-y: hidden;
				}
			}
			.paginator-main-content {
				margin: 20px 0;
			}
		}
	}
}

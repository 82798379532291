.service-order-tech-numbers-modal {
	width: 100%;

	.tech-number-header {
		border-radius: 6px 6px 0 0;
		justify-content: flex-start;
		height: 40px;

		span {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0.18px;
		}
	}

	.tech-number-list {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		flex-direction: row;

		.tech-number-option {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 20px;
			margin-top: 15px;

			svg {
				width: 10px;
				height: 10px;
				margin-right: 4px;
			}

			span {
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.18px;
			}
		}
	}
}

@import "../colors/variables.scss";

/*************************************/
/*************************************/
/*********** BACK COLORS *************/
/*************************************/
/*************************************/

.main-page-content.dark-theme {
	background-color: $grey20-color;

	.equips-header-content {
		.options-list-area {
			.input-search-field .MuiOutlinedInput-root:hover {
				background-color: $grey20-color;
			}
			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus {
				-webkit-text-fill-color: $blue5D-color !important;
				-webkit-box-shadow: 0 0 0px 1000px $grey20-color inset;
				transition: background-color 5000s ease-in-out 0s;
			}
		}
	}

	.orders-header-content {
		.options-list-area {
			.list-options {
				.input-search-field {
					.MuiOutlinedInput-root:hover {
						background-color: $grey20-color;
					}
					input:-webkit-autofill,
					input:-webkit-autofill:hover,
					input:-webkit-autofill:focus {
						-webkit-text-fill-color: $blue5D-color !important;
						-webkit-box-shadow: 0 0 0px 1000px $grey20-color inset;
						transition: background-color 5000s ease-in-out 0s;
					}
				}
			}
		}
	}

	.main-form-content {
		.form-item-content {
			input,
			textarea {
				background-color: $grey27-color;
			}

			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus {
				-webkit-text-fill-color: $whiteF8-color !important;
				-webkit-box-shadow: 0 0 0px 1000px $grey27-color inset;
				transition: background-color 5000s ease-in-out 0s;
			}

			.MuiIconButton-label div {
				color: $whiteF8-color;
			}

			input:disabled,
			textarea:disabled {
				background-color: $grey27-color;
			}
		}
	}

	.main-web-menu {
		background-color: $grey20-color;
		box-shadow: 0px 0px 6px $blue5D-color !important;
	}

	.main-mobile-menu {
		background-color: $blue46-color;
	}

	.user-select-options-item.active {
		background-color: $blue02-color;
		border-radius: 15px;
	}
	.main-mobile-menu-options {
		background-color: $blue02-color;
	}

	.user-area-selector {
		background-color: $grey33-color;
	}

	.user-area-selector .user-area-selector-item:hover {
		background-color: $grey20-color;
	}

	.user-select-options-item.notify .user-notifications-badge,
	.user-notifications-area .user-notifications-badge {
		background-color: $orangeE5-color;
	}

	.user-client-options-item.locked,
	.user-client-options-item.active {
		background-color: $blue02-color;
		border-radius: 6px;
	}

	.client-area-selector {
		background-color: $grey20-color;
		box-shadow: 0px 3px 6px $black00-color;
	}

	.client-area-selector .client-configs {
		background-color: $grey33-color;
	}

	.client-area-selector .loading-main-content {
		background-color: $grey20-color;
	}

	.client-area-selector.mobile {
		background-color: $blue00-color;
		box-shadow: none;
	}

	.client-area-selector.mobile .client-configs {
		background-color: $blueF1-15-color;
	}

	.client-area-selector.mobile .loading-main-content {
		background-color: $blue00-color;
	}

	.client-area-selector.mobile {
		.input-search-field .MuiInput-underline:hover,
		.input-search-field .MuiInput-underline:hover:not(.Mui-disabled):before,
		.input-search-field .MuiOutlinedInput-notchedOutline,
		.input-search-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
		.input-search-field .MuiInput-underline::before,
		.input-search-field .MuiInput-underline::after {
			border-color: $whiteF8-color;
		}
	}

	.service-order-address-content .MuiIconButton-colorSecondary:hover,
	.service-order-feedback-content .MuiIconButton-colorSecondary:hover,
	.client-selector-list .MuiIconButton-colorSecondary:hover {
		background-color: $blue02-2D-color;
	}

	.client-area-selector.mobile {
		.client-selector-list#client-list-items {
			/* Track */
			&::-webkit-scrollbar-track {
				background: transparent;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: $blue0b-color;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: $blue0b-color;
			}
		}
	}

	.dropdown-content {
		background-color: $grey27-color;
	}

	.dropdown-content .MuiSelect-select:focus {
		background-color: $grey27-color;
	}

	.user-client-options-item.notify .user-notifications-badge {
		background-color: $orangeE5-color;
	}

	.notify-center-mobile-content {
		.notify-center-header {
			background-color: $blue46-color;
		}

		.notify-list-mobile-content {
			background-color: $whiteF8-color;
		}

		.input-search-field .MuiInput-underline:hover,
		.input-search-field .MuiInput-underline:hover:not(.Mui-disabled):before,
		.input-search-field .MuiOutlinedInput-notchedOutline,
		.input-search-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
		.input-search-field .MuiInput-underline::before,
		.input-search-field .MuiInput-underline::after {
			border-color: $whiteF8-color;
		}
	}

	.input-search-field .MuiInput-underline:hover,
	.input-search-field .MuiInput-underline:hover:not(.Mui-disabled):before,
	.input-search-field .MuiOutlinedInput-notchedOutline,
	.input-search-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.input-search-field .MuiInput-underline::before,
	.input-search-field .MuiInput-underline::after {
		border-color: $blue5D-color;
	}

	.equip-list-content .loading-main-content {
		background-color: $grey20-color;
	}

	.equip-list-content .list-item {
		background-color: transparent;
	}

	.orders-list-content .list-item {
		background-color: $grey33-color;
	}

	.equip-list-content .list-item:nth-child(odd) {
		background-color: $grey33-color;
	}

	.orders-list-content .list-item:nth-child(odd) {
		background-color: transparent;
	}

	.equips-main-content {
		.equips-header-content {
			.options-list-area {
				.equips-main-more-options {
					.MuiSvgIcon-root {
						color: $blue5D-color;
						fill: $blue5D-color;
					}
				}
			}
		}
	}

	.orders-list-content .loading-main-content {
		background-color: $grey20-color;
	}

	.equip-list-content .has-error-content,
	.orders-list-content .has-error-content {
		background-color: $grey20-color;
	}

	.orders-filter-content .period-filter .period-filter-fields .MuiInputBase-root {
		background-color: $grey27-color;
	}

	.paginator-main-content .MuiPaginationItem-page {
		background-color: $grey76-color;
	}

	.paginator-main-content .MuiPaginationItem-page.Mui-selected {
		background-color: $whiteF8-color;
	}

	.paginator-main-content .MuiPaginationItem-page:hover {
		background-color: $blueD0-color;
	}

	.status-circle.closed {
		background-color: $aqua17-color;
	}

	.status-circle.progress {
		background-color: $orangeE5-color;
	}

	.modal-component {
		background-color: $grey20-color;
		box-shadow: 0px 0px 6px $black00-color;
	}

	.modal-component .modal-header-content {
		background-color: $grey33-color;
	}

	.modal-component .modal-header-content .modal-header-close-content {
		background-color: $whiteF8-color;
	}

	.modal-mobile-component {
		background-color: $grey20-color;
	}

	.equip-list-items .list-item.mobile:nth-child(odd) {
		background-color: $grey33-color;
	}

	.custom-button-primary {
		background: $blue5D-color;
		background: -webkit-linear-gradient(top, $blue5D-color, $blue02-color);
		background: -moz-linear-gradient(top, $blue5D-color, $blue02-color);
		background: linear-gradient(to bottom, $blue5D-color, $blue02-color);
	}

	.custom-button-secondary {
		background: $orangeE5-color;
		background: -webkit-linear-gradient(top, $orangeE5-color, $redC2-color);
		background: -moz-linear-gradient(top, $orangeE5-color, $redC2-color);
		background: linear-gradient(to bottom, $orangeE5-color, $redC2-color);
	}

	.notify-center-mobile-content {
		background-color: $grey20-color;

		.notify-center-content {
			background-color: $grey20-color;

			.has-error-content,
			.empty-main-content,
			.loading-main-content {
				background-color: $grey20-color;
			}
		}
	}

	.notify-center-main-content {
		background-color: $grey20-color;

		.notify-center-header {
			background-color: $grey33-color;

			.notify-center-close {
				background-color: $whiteF8-color;

				.MuiSvgIcon-root {
					color: $blue5D-color;
				}
			}
		}

		.notify-center-content {
			background-color: $grey20-color;

			.has-error-content,
			.empty-main-content,
			.loading-main-content {
				background-color: $grey20-color;
			}
		}

		.notify-center-option {
			background-color: $grey20-color;
		}
		.notify-center-footer {
			background-color: $grey33-color;
		}
	}

	.notify-list-item {
		background-color: $grey20-color;
	}

	.notify-list-item:hover {
		background-color: $grey6A-color;
	}

	.notify-list-item-mobile:nth-child(even) {
		background-color: $grey20-color;
	}

	.notify-list-item-mobile:nth-child(odd) {
		background-color: $grey33-color;
	}

	.notifications-list-item:nth-child(odd) {
		background-color: $grey33-color;
	}

	.notifications-list-content {
		.has-error-content,
		.empty-main-content,
		.loading-main-content {
			background-color: $grey20-color;
		}
	}

	.equipment-details-content {
		.equip-web-info-content {
			background-color: $grey33-color;
			box-shadow: 5px 0px 10px $black00-color;
		}

		.has-error-content,
		.empty-main-content,
		.loading-main-content {
			background-color: $grey20-color;
		}
	}

	.equip-orders-list-items,
	.equip-orders-list-mobile-items {
		.list-item:nth-child(odd) {
			background-color: $grey33-color;
		}
	}

	.equip-orders-list-mobile-content {
		.equip-orders-title {
			background-color: $blue02-color;
		}
	}

	.service-order-details-content {
		background-color: $grey33-color;

		.service-order-web-info-content,
		.service-order-mobile-info-content {
			background-color: $grey20-color;
		}

		.service-order-web-info-content {
			box-shadow: 5px 0px 10px $black00-color;
		}

		.has-error-content,
		.empty-main-content,
		.loading-main-content {
			background-color: $grey20-color;
		}

		.service-order-contact {
			.contact-area {
				.call-app-button {
					background: $blue5D-color;
					background: -webkit-linear-gradient(top, $blue5D-color, $blue02-color);
					background: -moz-linear-gradient(top, $blue5D-color, $blue02-color);
					background: linear-gradient(to bottom, $blue5D-color, $blue02-color);
				}

				.whats-app-button {
					border-color: $blue02-color;
				}
			}

			.service-order-tech-numbers-modal {
				background-color: transparent;

				.tech-number-header {
					background-color: transparent;
				}
			}
		}
	}

	.service-order-timeline-content {
		background: $grey33-color;
	}

	.service-order-timeline-content {
		#timeline-list {
			/* Track */
			&::-webkit-scrollbar-track {
				background: $grey20-color;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: $grey75-color;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: $grey33-color;
			}
		}
	}

	.timeline-progress-line,
	.timeline-progress-line-dots,
	.timeline-progress-line-finished {
		.progress-line,
		.progress-line-dot,
		.progress-line-start-dot {
			background-color: $blue5D-color;
		}

		.progress-line.start.hide {
			background-color: transparent;
		}
	}

	.service-order-tech-files {
		background-color: $grey20-color;

		.tech-files-header {
			background-color: $blue02-color;
		}
	}

	.service-order-timeline-file-link {
		div {
			box-shadow: 0px 0px 5px $black00-color;
		}

		div:hover {
			background-color: $blue5D-color;
		}
	}

	.service-orders-files-center-content {
		.files-center-list-content {
			.files-center-list-item-content:nth-child(odd) {
				background-color: $grey33-color;
			}
		}
	}

	.response-modal-web {
		background-color: $grey20-color;

		.modal-header-content {
			background-color: $grey33-color;
		}
	}

	.response-modal-mobile {
		background: $grey20-color;
		background: -moz-linear-gradient(180deg, $grey20-color 50%, $blue28-color 100%);
		background: -webkit-linear-gradient(180deg, $grey20-color 50%, $blue28-color 100%);
		background: linear-gradient(180deg, $grey20-color 50%, $blue28-color 100%);

		.modal-header-image .modal-header-close-content {
			background-color: $grey20-color;
		}

		.modal-action-content {
			button {
				background: $blue5D-color;
				background: -webkit-linear-gradient(top, $blue5D-color, $blue02-color);
				background: -moz-linear-gradient(top, $blue5D-color, $blue02-color);
				background: linear-gradient(to bottom, $blue5D-color, $blue02-color);
			}
		}
	}

	.upload-file-main-content {
		.upload-file-list-item {
			background: $grey33-color;

			.upload-file-list-item-remove {
				background: $orangeE5-color;
				background: -webkit-linear-gradient(top, $orangeE5-color, $redC2-color);
				background: -moz-linear-gradient(top, $orangeE5-color, $redC2-color);
				background: linear-gradient(to bottom, $orangeE5-color, $redC2-color);
			}
		}
	}

	.service-order-feedback-content {
		.form-item-content-line .line {
			background-color: $blue5D-color;
		}
	}

	.mobile-app-settings-main-content .mobile-app-settings-item,
	.app-settings-main-content .app-settings-item {
		input:checked + .slider.round {
			background: transparent linear-gradient(180deg, $blue5D-color 0%, var(--unnamed-color-2f72cf) 100%) 0% 0%
				no-repeat padding-box;
			background: transparent linear-gradient(180deg, $blue5D-color 0%, $blue2f-color 100%) 0% 0% no-repeat padding-box;
		}

		input:checked + .slider.round:before {
			background-color: $whiteF8-color;
		}

		input:not(:checked) + .slider.round {
			background-color: $grey20-color;
			border: 2px solid $blue57-color;
		}

		input:not(:checked) + .slider.round:before {
			background: transparent linear-gradient(180deg, $blue5D-color 0%, var(--unnamed-color-2f72cf) 100%) 0% 0%
				no-repeat padding-box;
			background: transparent linear-gradient(180deg, $blue5D-color 0%, $blue2f-color 100%) 0% 0% no-repeat padding-box;
		}
	}

	.mobile-help-main-content,
	.mobile-settings-main-content,
	.mobile-app-settings-main-content,
	.mobile-edit-account-content {
		background-color: $grey20-color;

		.mobile-app-settings-item {
			border-color: $blue5D-color;
		}
	}

	.mobile-edit-account-content {
		.main-header {
			button {
				background: $blue5D-color;
				background: -webkit-linear-gradient(top, $blue5D-color, $blue02-color);
				background: -moz-linear-gradient(top, $blue5D-color, $blue02-color);
				background: linear-gradient(to bottom, $blue5D-color, $blue02-color);
			}

			.edit-button {
				border-color: $whiteF8-color;
			}
		}
	}

	.web-help-main-content {
		.help-contact-area-content {
			.email-button,
			.tutorial-button {
				border-color: $whiteF8-color;
			}
		}

		.help-contact-area-action {
			a {
				background-color: $grey33-color;
				box-shadow: 0px 0px 3px #000000;
				border: none;
			}
		}
	}

	.mobile-help-main-content {
		.help-contact-area {
			border-color: $blue5D-color;
		}

		.help-contact-area-action {
			a {
				background-color: $grey33-color;
				box-shadow: 0px 0px 3px #000000;
				border: none;
			}
		}

		.help-contact-area-content {
			.email-button,
			.tutorial-button {
				border-color: $whiteF8-color;
			}
		}

		.help-form-area,
		.help-contact-area {
			background-color: $grey20-color;
		}
	}

	.stores-settings-mobile-list-item:nth-child(odd) {
		background-color: $grey33-color;
	}

	.stores-settings-list-item:nth-child(odd) {
		background-color: $grey33-color !important;
	}

	.stores-settings-list-content {
		background-color: $grey20-color;

		.has-error-content,
		.empty-main-content,
		.loading-main-content {
			background-color: $grey20-color;
		}
	}

	.stores-settings-main-content {
		background-color: $grey20-color;

		.has-error-content,
		.empty-main-content,
		.loading-main-content {
			background-color: $grey20-color;
		}
	}

	.stores-settings-mobile-header-content {
		background-color: $blue00-color;

		.stores-settings-center-search {
			.input-search-field .MuiInput-underline:hover,
			.input-search-field .MuiInput-underline:hover:not(.Mui-disabled):before,
			.input-search-field .MuiOutlinedInput-notchedOutline,
			.input-search-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
			.input-search-field .MuiInput-underline::before,
			.input-search-field .MuiInput-underline::after {
				border-color: $whiteF8-color;
			}
		}
	}

	.stores-settings-mobile-list-content {
		.stores-settings-mobile-search-content {
			background-color: $grey20-color;
		}
	}

	.custom-button-primary.outline-button,
	.custom-button-prisecondaryary.outline-button {
		background: transparent;
		background-color: transparent;
	}

	.equip-add-main-content {
		.equip-add-conflict-message {
			background-color: $grey33-color;
		}
	}

	.empty-user-main-content {
		.empty-user-main {
			.empty-user-actions {
				div {
					background: $blue5D-color;
					background: -webkit-linear-gradient(top, $blue5D-color, $blue02-color);
					background: -moz-linear-gradient(top, $blue5D-color, $blue02-color);
					background: linear-gradient(to bottom, $blue5D-color, $blue02-color);
				}
			}
		}
	}
	.page-list-options-content {
		background-color: $grey20-color;
		.input-search-field {
			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus {
				-webkit-text-fill-color: $grey20-color !important;
				-webkit-box-shadow: 0 0 0px 1000px $whiteF2-color inset;
				transition: background-color 5000s ease-in-out 0s;
			}
		}
	}

	.shadow-on-scroll {
		.equip-list-header,
		.orders-list-header,
		.orders-label-layer,
		.stores-settings-mobile-search-content,
		.equip-orders-list-header {
			box-shadow: 0px 5px 5px $black00-color;
		}
	}

	@media only screen and (max-device-width: 480px) {
		/* styles for mobile browsers smaller than 480px; (iPhone) */
		.shadow-on-scroll {
			.equips-header-content,
			.orders-header-content {
				box-shadow: 0px 5px 5px $black00-color;
			}
		}
	}
}

/** ESPECIAL STYLES FOR MATERIAL-UI DROPDOWN AND POPUP **/
body.dark-theme {
	.dropdown-list-item.dark-theme {
		background-color: $grey33-color;
	}

	.modal-main-content-success {
		.modal-component {
			.modal-main-content {
				background-color: $grey20-color;
			}

			.modal-header-content {
				background-color: $grey33-color;

				.modal-header-close-content {
					background-color: $whiteF8-color;
				}
			}
		}

		.modal-component-tutorial .modal-header-content-tutorial {
			background-color: transparent;
		}
	}

	.MuiListItem-button {
		background-color: $grey33-color;
	}

	.dropdown-list-item.dark-theme:hover,
	.dropdown-list-item.dark-theme.MuiListItem-root.Mui-selected,
	.dropdown-list-item.dark-theme.MuiListItem-root.Mui-selected:hover {
		background-color: $grey20-color;
	}

	.MuiList-root.MuiMenu-list.MuiList-padding .MuiMenuItem-root {
		background-color: $grey27-color;
	}

	.MuiList-root.MuiMenu-list.MuiList-padding {
		background-color: $grey27-color;
	}

	.MuiList-root.MuiMenu-list.MuiList-padding .MuiMenuItem-root:hover {
		background-color: $grey20-color;
	}

	.MuiAutocomplete-paper {
		background-color: $grey20-color;
		box-shadow: 0px 0px 5px $black00-color;
	}

	#defect-field-selector-popup {
		background-color: $grey20-color;

		.MuiAutocomplete-option:hover {
			background-color: $grey33-color;
		}

		.MuiAutocomplete-option[aria-selected="true"] {
			background-color: $grey33-color;
		}
	}

	.MuiDialog-root .MuiPickersToolbar-toolbar {
		background-color: $grey33-color;
	}

	.MuiDialog-root .MuiPaper-root,
	.MuiDialog-root .MuiPickersCalendarHeader-iconButton,
	.MuiDialog-root .MuiPickersBasePicker-pickerView {
		background-color: $grey20-color;
	}

	.MuiDialog-root .MuiPickersDay-daySelected {
		background-color: $blue5D-color;
	}

	.MuiDialog-root .MuiPickersDay-daySelected:hover {
		background-color: $blue2D-color;
	}

	.MuiButton-label {
		color: $whiteF8-color;
	}

	#notifications-list-item-options,
	#notifications-list-options {
		.MuiMenu-paper {
			background-color: $whiteF2-color;
		}

		.MuiButtonBase-root:hover {
			background-color: $blueD0-color;
		}
	}

	.open-service-order-content .service-order-address-content .service-order-address-selector .MuiIconButton-label div {
		color: $whiteF8-color;
	}
}

body.dark-theme {
	background-color: $grey20-color !important;
	.main-page-content,
	.main-page-component {
		background-color: $grey20-color !important;
	}

	#update-notification-bar {
		background: $grey33-color;
		box-shadow: 0px 5px 10px $black00-color;
	}
}

body.dark-theme #root {
	/* Track */
	::-webkit-scrollbar-track {
		background-color: $grey20-color;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background-color: $grey75-color;
	}

	/* Handle on hover */

	::-webkit-scrollbar-thumb:hover {
		background-color: $grey33-color;
	}
}

.cancel-service-order-main-content {
	width: 650px !important;
	overflow-y: auto;

	.cancel-service-order-content {
		padding: 0px 2px;
		margin-bottom: 20px;

		p {
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.24px;
			margin: 0px 0px 10px;
		}

		span {
			display: block;
			margin: 10px 0;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.2px;
		}

		.service-order-content-message-progres {
			margin: auto;
			font-size: 15px;
		}
	}

	.cancel-service-actions {
		display: flex;
		justify-content: center;
	}
}

@media screen and (min-width: 480px) and (min-height: 980px) and (max-width: 900px) and (max-height: 1280px) and (orientation: portrait) {
	.open-service-order-content {
		width: 650px;
		max-width: 100%;
	}
}

@media screen and (max-width: 480px) and (max-height: 980px) and (orientation: portrait) {
	.cancel-service-order-main-content {
		width: 100% !important;
		height: 100%;

		.main-form-content {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.cancel-service-order-content {
				.form-item-content {
					height: auto;
					textarea {
						height: 200px;
					}
				}
			}

			.cancel-service-actions {
				display: flex !important;
				justify-content: space-around;
			}
		}
	}
}

.cancel-service-order-main-content-error {
	width: 440px !important;
}

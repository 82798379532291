.equip-mobile-info-content {
	width: 100%;
	.equip-info-warrant {
		padding-left: 50px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: row;
		font-size: 12px;
		letter-spacing: 0.18px;

		b {
			font-weight: 500;
			margin-right: 5px;
		}

		span {
			font-weight: 400;
		}
	}

	.equip-info-extra {
		width: 100%;
		padding: 10px 20px;

		.equip-info-location {
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: column;

			.location-item {
				width: 100%;
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				flex-direction: row;

				.loacaiton-item-label {
					width: 75px;
				}

				.loacaiton-item-value {
					width: calc(100% - 75px);
				}
			}

			span {
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.18px;
				text-transform: capitalize;
			}
		}

		.equip-open-service {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-direction: row;
			margin: 15px 0;
			cursor: pointer;

			span {
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.18px;
			}

			.briefcase-icon {
				margin-right: 8px;
				height: 18px;
				width: 18px;
			}
		}
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
}

@media only screen and (device-width: 768px) {
	/* default iPad screens */
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.equip-mobile-info-content {
		.equip-info-warrant {
			padding-left: 65px;
		}

		.equip-info-extra {
			.equip-info-location {
				width: 100%;
				margin: 5px 0;
				flex-direction: row;
				flex-wrap: wrap;

				.location-item {
					width: 50%;

					.loacaiton-item-label {
						width: auto;
						margin-right: 5px;
						span {
							font-weight: 500;
						}
					}

					.loacaiton-item-value {
						width: auto;
					}
				}
			}
		}
	}
}

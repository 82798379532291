.upload-file-main-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;

    .upload-file-action {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        background: transparent;
        height: 30px;
        padding: 0px;
        cursor: pointer;

        svg {
            width: 20px;
            height: 20px;
        }

        span {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.18px;
        }
    }

    .upload-file-action-input {
        display: none;
    }

    .upload-file-list {
        padding: 0px 20px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
    }

    .upload-file-list-item {
        width: 60px;
        height: 16px;
        margin-right: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;

        span {
            font-size: 10px;
            font-weight: 400;
        }

        .upload-file-list-item-remove {
            position: absolute;
            width: 10px;
            height: 10px;
            top: -3px;
            right: -3px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                width: 6px;
                height: 6px;
            }
        }
    }
}

.upload-file-warning {
    width: 100%;
    padding: 0px 8px;

    span {
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.18px;
    }
}

@media screen and (max-width: 500px) {
    .upload-file-main-content {
        flex-direction: column;
    }
}
.input-search-field {
	padding: 0px !important;
	width: 100%;

	div.MuiOutlinedInput-adornedStart:hover {
		padding-left: 7px;
	}

	input.MuiOutlinedInput-input {
		padding: 0px !important;
		height: 40px !important;
	}
	.MuiOutlinedInput-adornedStart {
		padding-left: 7px;
	}
	.MuiOutlinedInput-root {
		border-radius: 7px;
	}

	.MuiSvgIcon-root {
		transform: rotate(90deg);
		font-size: 18px;
	}

	input.MuiOutlinedInput-input::placeholder {
		opacity: 1;
	}

	input.MuiOutlinedInput-input:hover::placeholder {
		font-style: italic;
	}

	input.MuiOutlinedInput-input::placeholder,
	input.MuiInputBase-input {
		font-size: 12px;
		letter-spacing: 0px;
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
	}

	.input-search-field .MuiInput-underline:hover,
	.input-search-field .MuiInput-underline:hover:not(.Mui-disabled):before,
	.input-search-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.input-search-field .MuiInput-underline::before,
	.input-search-field .MuiInput-underline::after {
		border: 1px solid;
		outline: none !important;
		content: '' !important;
		box-shadow: none !important;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border: 1px solid;
	}
}

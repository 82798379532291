.equip-service-orders-web-content {
	position: relative;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;

	.equip-orders-list-content {
		height: calc(100% - 47px);

		.loading-main-content {
			top: 0px;
		}
	}
}

@media screen and (max-width: 900px) {
	.equip-service-orders-web-content {
		.equip-orders-list-content {
			overflow-y: auto;
		}
	}
}

.mobile-page-header-content {
	width: 100%;
	padding: 15px 15px 0 15px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	.mobile-navigator-component {
		width: 35px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-title-content {
		width: calc(100% - 35px);
		height: 100%;
		text-transform: uppercase;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;

		h4 {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0.21px;
			margin: 0px 0px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		h5 {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0.21px;
			margin: 4px 0px 5px;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.mobile-page-header-content {
		padding: 15px 15px 0 0;

		.mobile-navigator-component {
			width: 65px;
			height: 50px;
			padding-left: 15px;
		}

		.page-title-content {
			width: calc(100% - 50px);
		}
	}
}

/**********************************/
/**********************************/
/********* ZENDESK WIDGET *********/
/**********************************/
/**********************************/

body > div:not(#root) > div > div {
	overflow: unset !important;
}

/* Chat Content */
body > div:not(#root) > div > div > iframe {
	// padding: 10px;
	box-shadow: 5px 0px 10px #00000080;
	border-radius: 10px;
}

/* Button Open Chat Content */
body > div:not(#root) > div > iframe {
	border-radius: 10px;
}

@media screen and (max-width: 1440px) {
	/* Chat Content */
	body > div:not(#root) > div > div > iframe {
		// padding: 10px;
		box-shadow: 5px 0px 10px #00000080;
		border-radius: 10px;
	}
}

@media screen and (max-device-width: 480px) {
	body > div:not(#root) > div > div:not(.MuiPaper-root) {
		bottom: 50px !important;
		display: none !important;
	}

	/* Button Open Chat Content */
	body > div:not(#root) > div > iframe {
		bottom: 50px !important;
		display: none !important;
	}

	body.show-chat > div:not(#root) > div > div {
		bottom: 55px !important;
		display: block !important;
	}

	body.show-chat > div:not(#root) > div > div > iframe {
		box-shadow: 0 !important;
		border-radius: 0px !important;
		z-index: 999 !important;
	}

	/* Button Open Chat Content */
	body.show-chat > div:not(#root) > div > iframe {
		bottom: 55px !important;
		display: block !important;
		z-index: 999 !important;
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	body > div:not(#root) > div > div:not(.MuiPaper-root) {
		display: none !important;
	}

	/* Button Open Chat Content */
	body > div:not(#root) > div > iframe {
		display: none !important;
	}

	body.show-chat > div:not(#root) > div > div {
		bottom: 55px !important;
		display: block !important;
	}

	body.show-chat > div:not(#root) > div > div > iframe {
		box-shadow: 0 !important;
		border-radius: 0px !important;
		z-index: 999 !important;
	}

	/* Button Open Chat Content */
	body.show-chat > div:not(#root) > div > iframe {
		bottom: 55px !important;
		display: block !important;
		z-index: 999 !important;
	}
}

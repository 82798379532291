.status-circle {
	width: 13px;
	height: 13px !important;
	border-radius: 100%;
	margin-right: 5px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 9px;
		height: 9px;
	}
}

.equip-service-orders-mobile-content {
	width: 100%;
	position: relative;

	.empty-main-content,
	.loading-main-content {
		height: 260px;
		top: 30px;
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.equip-service-orders-mobile-content {
		height: auto;
		padding-bottom: 15px;

		.equip-orders-list-mobile-content {
			height: auto;
			.equip-orders-list-mobile-items {
				height: auto;
			}
		}
	}
}

@media only screen and (device-width: 768px) {
	/* default iPad screens */
	.equip-service-orders-mobile-content {
		height: auto;
		padding-bottom: 15px;

		.equip-orders-list-mobile-content {
			height: auto;
			.equip-orders-list-mobile-items {
				height: auto;
			}
		}
	}
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.equip-service-orders-mobile-content {
		height: calc(100% - 200px);
		.equip-orders-list-mobile-content {
			height: calc(100% - 51px);
			.equip-orders-list-mobile-items {
				height: calc(100% - 66px);
				overflow-x: hidden;
				overflow-y: auto;
			}
		}
	}
}

.main-page-selector {
	width: 396px;
	height: 80px;
	position: fixed;
	top: 100px;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	z-index: 20;

	.item-selector {
		width: 168px;
		height: 30px;
		border-radius: 6px;
		border: 1px solid;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0px;
			text-transform: uppercase;
		}
	}

	.item-selector:hover {
		text-decoration: none;
	}
}

@media screen and (max-device-width: 480px) {
	.main-page-selector {
		width: 100%;
		justify-content: space-between;
		height: auto;
		padding: 20px;

		.item-selector {
			width: calc(50% - 10px);
		}
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	.main-page-selector {
		width: 100%;
		justify-content: space-between;
		height: auto;
		padding: 20px;
		top: 0;

		.item-selector {
			width: calc(50% - 10px);
		}
	}
}

.whats-app-button {
	width: 85px;
	height: 30px;
	margin: 0px 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	border-radius: 6px;

	.MuiSvgIcon-root {
		width: 16px;
		height: 16px;
		margin-right: 4px;
	}

	span {
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.18px;
	}
}

.whats-app-button {
	border: 1px solid;
}

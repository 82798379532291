.modal-main-content-success {
	.main-form-content .form-item-content {
		height: auto;
		label {
			font-size: 13px;
			letter-spacing: 0px;
			margin-bottom: 8px;
			align-items: center;
			justify-content: center;
		}
	}

	.modal-main-content {
		text-align: center;
		height: auto;
		width: 480px;
		align-items: center;
		justify-content: center;
		font-size: 13px;
		font-weight: 500;
		letter-spacing: 0px;
	}

	.modal-component-tutorial {
		width: 70%;
		height: auto;
		overflow-y: auto;

		.modal-main-content-tutorial {
			width: 100%;
			height: 100%;

			.modal-video-style {
				width: 100%;
				height: 100%;
			}
		}

		.modal-header-content-tutorial {
			z-index: 100;
			margin-left: 68vw;
			margin-top: 5px;
			position: absolute;
			width: 20px;
			height: 20px;
			border-radius: 20px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
		}
	}

	.modal-mobile-component {
		background: transparent !important;
	}

	.main-form-content .form-item-content {
		height: auto;
		label {
			font-size: 13px;
			letter-spacing: 0px;
			margin-bottom: 8px;
			align-items: center;
			justify-content: center;
			font-weight: 400;
		}
	}

	.modal-mobile-component-tutorial {
		width: 80%;
		height: auto;
		overflow-y: auto;
		.modal-mobile-main-content-tutorial {
			width: 100%;
			height: 100%;

			.modal-video-style {
				width: 100%;
				height: 100%;
			}
		}

		.modal-mobile-header-content-tutorial {
			position: fixed;
			z-index: 100;
			margin-left: 72%;
		}
	}

	.modal-mobile-component .modal-mobile-main-content {
		width: 100%;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		flex-wrap: unset;
	}
}

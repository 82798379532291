.equip-orders-list-items {
	.list-item {
		width: 100%;
		height: 50px;
		padding: 0px 60px;
		display: flex;
		align-content: center;
		justify-content: space-between;
		flex-direction: row;
		cursor: pointer;

		.order-info {
			width: 100%;
			display: flex;
			align-content: center;
			justify-content: flex-start;
			flex-direction: row;

			div {
				height: 100%;
				display: flex;
				align-items: center;
				text-align: left;
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.18px;
				text-transform: uppercase;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;

				span {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			.options {
				width: 40px;
				display: none;
				background: transparent;
				border: none;

				.MuiButtonBase-root {
					min-width: 40px;
					width: 40px;
				}
			}

			.created {
				width: 220px;
			}

			.protocol {
				width: 170px;
			}

			.status {
				width: 140px;
			}

			.tech {
				width: calc(100% - (220px + 170px + 140px + 40px));
			}
		}
	}

	.list-item:hover {
		.order-info {
			.options {
				display: flex;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.equip-orders-list-items {
		.list-item {
			padding: 0px 25px;

			.order-info {
				.created {
					width: 180px;
				}

				.status,
				.protocol {
					width: 120px;
				}

				.tech {
					width: calc(100% - (180px + 240px));
				}
			}
		}
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
	/* For portrait layouts only */
	.equip-orders-list-items {
		.list-item {
			.order-info {
				.created {
					width: 180px;
				}

				.protocol {
					width: 120px;
				}

				.status {
					width: 115px;
				}

				.tech {
					width: calc(100% - (180px + 120px + 118px + 40px));
				}

				.options {
					display: flex;
				}
			}
		}
	}
}

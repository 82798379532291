.notify-list-item {
    width: 100%;
    height: 90px;
    padding: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    background-color: transparent;
    cursor: pointer;

    .list-item {
        width: 100%;
    }

    .list-item {
        display: flex;
        flex-direction: row;
    }

    .title {
        height: 50px;
        margin-bottom: 10px;
        align-items: flex-start;
        justify-content: flex-start;

        span {
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.18px;
            opacity: .7;
        }

        .not-read {
            font-weight: 500;
            opacity: 1;
        }
    }

    .options {
        align-items: center;
        justify-content: space-between;

        .mark-as,
        .unmark-as {
            cursor: pointer;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.18px;
        }

        .mark-as {
            text-decoration: underline;
        }

        .notify-time {
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.18px;
        }
    }
}
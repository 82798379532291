.orders-main-content {
	width: 100%;
	height: 100%;
	padding: 20px 0;

	.orders-header-content {
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.options-list-area {
			width: calc(50% + 120px);
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.orders-label-layer {
			width: 100%;
		}
	}
}

.orders-main-content.shadow-on-scroll {
	.orders-list-header {
		width: 100%;
		z-index: 10;
		position: relative;
		padding-right: 20px;
	}

	.orders-list-items {
		margin-top: 0px !important;
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.orders-main-content {
		padding: 0px 0px 20px;

		.orders-header-content {
			.options-list-area {
				width: 100%;

				.orders-status-label {
					display: none;
				}
			}
		}
	}

	.orders-main-content.shadow-on-scroll {
		.orders-header-content {
			height: 55px;
			padding-bottom: 15px;

			position: relative;
			z-index: 10;
		}

		.orders-list-content {
			margin-top: 0px !important;
			margin-bottom: 55px !important;
		}
	}
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.orders-main-content {
		padding: 0px 0px 20px;

		.orders-header-content {
			.options-list-area {
				width: 100%;

				.orders-status-label {
					display: none;
				}
			}
		}
	}

	.orders-main-content.shadow-on-scroll {
		.orders-list-content {
			.orders-label-layer {
				position: relative;
				z-index: 10;

				.orders-status-label {
					margin: 0 auto;
				}
			}
		}
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
	/* For portrait layouts only */

	.orders-main-content {
		padding: 21px 0px 19px;
	}
}

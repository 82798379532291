.equip-orders-list-mobile-items {
	.list-item {
		width: 100%;
		height: 26px;
		padding: 0px 20px;
		display: flex;
		align-content: center;
		justify-content: space-between;
		flex-direction: row;
		cursor: pointer;

		.order-info {
			width: 100%;
			height: 100%;
			display: flex;
			align-content: center;
			justify-content: flex-start;
			flex-direction: row;

			div {
				height: 100%;
				display: block;
				align-items: center;
				text-align: left;
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.18px;

				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: flex-start;

				span {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			.options {
				width: 40px;
				display: contents;
				background: transparent;
				border: none;

				.MuiButtonBase-root {
					min-width: 40px;
					width: 40px;
				}
			}

			.created {
				width: 220px;
			}

			.protocol {
				width: 170px;
				text-transform: uppercase;
			}

			.status {
				width: 140px;
			}

			.tech {
				width: calc(100% - (220px + 170px + 140px + 40px));
			}
		}
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.equip-orders-list-mobile-items {
		.list-item {
			padding: 0 5px 0 20px;
			height: 40px;
			.order-info {
				.created,
				.protocol,
				.status {
					width: calc((100% - 40px) / 3);
				}

				.tech {
					display: none;
				}
			}
		}
	}
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.equip-orders-list-mobile-items {
		.list-item {
			height: 40px;

			.order-info {
				.options {
					width: 40px;
				}

				.created {
					width: 150px;
				}

				.protocol {
					width: 150px;
					text-transform: uppercase;
				}

				.status {
					width: 140px;
				}

				.tech {
					width: calc(100% - 480px);
				}
			}
		}
	}
}

.timeline-progress-line {
    width: 10px;
    height: inherit;
    margin: 0px 25px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .progress-line {
        width: 2px;
        height: calc(100% - 35px);
    }

    .progress-line.start {
        height: 25px;
    }
}

.timeline-progress-line-dots {
    width: 10px;
    height: inherit;
    margin: 0px 25px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .progress-line {
        height: calc(100% - 52px);
    }

    .progress-line.start {
        height: 25px;
    }

    .progress-line-dot {
        height: 4px;
        margin-top: 2px;
    }
}

.timeline-progress-line,
.timeline-progress-line-dots,
.timeline-progress-line-finished {
    .progress-line-start-dot {
        width: 10px;
        height: 10px;
        border-radius: 5px;
    }

    .progress-line,
    .progress-line-dot {
        width: 2px;
    }

    .progress-line.start {
        height: 25px;
    }
}

.timeline-progress-line-finished {
    width: 10px;
    height: fit-content;
    margin: 0px 25px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .progress-line.start {
        height: 25px;
    }
}

@media screen and (max-width: 900px) {

    .timeline-progress-line {
        margin: 0px 10px 0px;

        .progress-line {
            height: calc(100% - 32px);
        }

        .progress-line.start {
            height: 22px;
        }
    }

    .timeline-progress-line-dots {
        margin: 0px 10px 0px;

        .progress-line {
            height: calc(100% - 47px);
        }

        .progress-line.start {
            height: 22px;
        }
    }

    .timeline-progress-line-finished {
        width: 10px;
        margin: 0px 10px 0px;

        .progress-line.start {
            height: 22px;
        }
    }
}
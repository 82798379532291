.page-list-options-content {
	width: 530px;
	max-width: 100%;
	height: 40px;
	margin: 0 20px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	flex-direction: row;
	position: relative;
	background-color: inherit;

	.input-search-field {
		background-color: inherit;
		width: calc(100% - 125px);
		-webkit-transition: width 150ms ease-in-out;
		-moz-transition: width 150ms ease-in-out;
		-ms-transition: width 150ms ease-in-out;
		-o-transition: width 150ms ease-in-out;
		transition: width 150ms ease-in-out;
	}

	.paginator-limit-component {
		width: 60px;
		margin-left: 3px;

		.MuiInputBase-root {
			width: 50px;
		}
	}

	.icon-button-action {
		width: 50px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		.MuiSvgIcon-root {
			width: 40px;
			height: 40px;
		}
	}
}

.page-list-options-content.non-button {
	justify-content: flex-end;
	.input-search-field {
		width: calc(100% - 60px);
		margin-right: 20px;
	}
}

@media only screen and (max-device-width: 380px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.page-list-options-content {
		.paginator-limit-component {
			.MuiInputBase-root {
				width: 40px;
			}

			.MuiSelect-icon {
				top: 2px;
				right: -3px;
			}
		}
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.page-list-options-content {
		.input-search-field.focused {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
		}

		.paginator-limit-component {
			width: 50px;
			margin-left: 3px;

			.MuiInputBase-root {
				width: 40px;
			}
		}
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1023px) and (orientation: portrait) {
	/* For portrait layouts only */
	.page-list-options-content {
		width: 100%;
		.paginator-limit-component {
			width: 50px !important;
			margin-left: 3px;

			.MuiInputBase-root {
				width: 40px !important;
			}
		}
	}
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.page-list-options-content {
		width: 100%;

		.paginator-limit-component {
			width: 55px;
			margin-left: 3px;

			.MuiInputBase-root {
				width: 50px;
			}
		}
	}
}

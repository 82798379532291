.stores-settings-mobile-list-items {
	.stores-settings-list-item {
		width: 100%;
		height: 40px;
		padding: 0px 20px;
		display: flex;
		align-content: center;
		justify-content: space-between;
		flex-direction: row;
		cursor: pointer;

		.stores-settings-info {
			width: calc(100% - 25px);
			display: flex;
			align-content: center;
			justify-content: flex-start;
			flex-direction: row;

			div {
				height: 100%;
				display: flex;
				align-items: center;
				text-align: left;
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.18px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;

				span {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}

				.MuiFormGroup-root,
				.MuiFormControl-root {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-direction: row;
				}

				.MuiFormControlLabel-root {
					width: calc(50% - 16px);
				}

				.MuiTypography-body1 {
					font-size: 12px;
					font-weight: 400;
					letter-spacing: 0.18px;
					opacity: 1;
				}
			}

			.name,
			.full-name {
				width: 30%;
			}

			.equips {
				width: 15%;
			}

			.users {
				width: 25%;
				text-transform: capitalize;
			}
		}
	}

	@media screen and (max-width: 1300px) {
		.stores-settings-list-item {
			.stores-settings-info {
				.name,
				.full-name {
					width: 30%;
				}

				.equips {
					width: 15%;
				}

				.users {
					width: 25%;
				}
			}
		}
	}
}

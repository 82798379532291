.custom-border-dotted {
    width: 100%;
    height: 1px;
    overflow: hidden;

    div {
        width: 900px;
        height: 1px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2333333340' stroke-width='2' stroke-dasharray='1%2c13' stroke-dashoffset='13' stroke-linecap='round'/%3e%3c/svg%3e");
    }
}
.service-order-feedback-content {
	.characters-remaining{
		font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.18px;
	}
	
	.service-order-feedback-click-item {
		text-decoration: underline;
		font-size: 13px;
		font-weight: 400;
		letter-spacing: 0.2px;
		cursor: pointer;
	}
	.service-order-feedback-form {
		width: 360px;
		.feedback-form-header {
			h5 {
				font-size: 13px;
				font-weight: 500;
				letter-spacing: 0.2px;
				margin: 0px 0px 20px;
			}
		}
		.main-form-content {
			width: 385px;
			height: 365px;
			margin-right: -25px;
			overflow-y: auto;
			overflow-x: hidden;
			/* Track */
			&::-webkit-scrollbar-track {
				display: none;
			}
			/* Handle */
			&::-webkit-scrollbar-thumb {
				display: none;
			}
			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				display: none;
			}
			.form-item-content {
				height: auto;
				padding-right: 20px;
				label {
					margin-top: 8px;
					margin-bottom: 0px;
					.feedback-question-order {
						font-size: 14px;
						font-weight: 600;
						letter-spacing: 0.21px;
						margin-right: 5px;
					}
				}
				textarea {
					margin-top: 10px;
					font-weight: 400;
				}
			}
			.form-item-option-content,
			.form-item-rating-content {
				width: 100%;
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-direction: row;
				margin-bottom: 10px;
				.form-item-rating-label {
					width: auto;
					height: 40px;
					text-align: center;
					display: flex;
					align-items: flex-end;
					span {
						font-size: 12px;
						font-weight: 400;
						letter-spacing: 0.18px;
					}
				}
			}
			.form-item-option-content {
				.MuiFormGroup-root {
					width: 100%;
					height: 60px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-direction: row;
					flex-wrap: unset;
				}
				.MuiFormControlLabel-root {
					width: 50%;
					margin: 0px !important;
					display: flex;
					align-items: center;
					justify-content: flex-start !important;
					flex-direction: row !important;
				}
			}
			.MuiFormGroup-root {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: row;
				.MuiFormControlLabel-root {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column-reverse;
					margin: 0px 5px;
				}
				.PrivateSwitchBase-root-19,
				.PrivateSwitchBase-root-1 {
					padding: 0px !important;
				}
				.MuiTypography-body1 {
					font-size: 12px;
					font-weight: 400;
					letter-spacing: 0.18px;
					font-family: Roboto, sans-serif;
					margin-right: 5px;
				}
			}
			.MuiFormControlLabel-root {
				margin: 0px;
			}
		}
		.feedback-form-actions {
			padding: 20px 20px 0px 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

@media screen and (max-width: 900px) {
	.service-order-feedback-content {
		.service-order-feedback-form {
			width: 100%;
			.main-form-content {
				width: calc(100% + 20px);
				height: auto;
				margin-right: -20px;
				overflow-x: hidden;
				.form-item-option-content,
				.form-item-rating-content {
					.form-item-rating-label {
						width: auto;
						height: 40px;
						span {
							font-size: 12px;
							font-weight: 400;
							letter-spacing: 0.18px;
						}
					}
				}
				.MuiFormGroup-root {
					.MuiFormControlLabel-root {
						margin: 0px 7px;
					}
					.PrivateSwitchBase-root-1 {
						padding: 0px !important;
					}
					.MuiTypography-body1 {
						font-size: 12px;
						font-weight: 400;
						letter-spacing: 0.18px;
						font-family: 'Roboto', sans-serif;
					}
				}
			}
			.feedback-form-actions {
				padding: 20px 20px 20px 0;
			}
		}
	}
}

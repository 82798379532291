.modal-main-backdrop {
	background: transparent;
	background-repeat: repeat;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 50;
	backdrop-filter: blur(5px);
}

.modal-component {
	box-shadow: 0px 0px 6px #3333334d;
	border-radius: 6px;

	.modal-header-content {
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px 6px 0px 0px;
		position: relative;
		padding: 0px 40px 0px 20px;

		span {
			text-align: center;
			font-size: 16px;
			font-weight: 600;
			letter-spacing: 0.24px;
			text-transform: uppercase;
		}

		.modal-header-close-content {
			position: absolute;
			width: 20px;
			height: 20px;
			border-radius: 20px;
			top: 15px;
			right: 15px;
			cursor: pointer;

			display: flex;
			align-items: center;
			justify-content: center;

			.MuiSvgIcon-root {
				width: 16px;
				height: 16px;
			}
		}
	}

	.modal-main-content {
		width: 100%;
		height: auto;
		padding: 20px;
	}
}

.modal-mobile-component {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	z-index: 15;

	.modal-mobile-main-content {
		width: 100%;
		height: calc(100% - 72px);
		padding: 20px;
	}
}

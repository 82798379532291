.orders-list-content {
	margin-top: 20px;
	height: 100%;
	display: flex;
	flex-direction: column;

	.orders-status-label {
		display: none;
	}

	.orders-list-header {
		display: flex;
		align-content: center;
		justify-content: flex-start;
		flex-direction: row;
		width: 100%;
		height: 30px;
		padding: 0px 20px;

		.list-header {
			display: flex;
			align-content: center;
			justify-content: flex-start;
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 0.2px;

			.MuiSvgIcon-root {
				width: 15px;
				height: 15px;
				margin-top: 2px;
				margin-left: 5px;
				cursor: pointer;
			}
		}

		.equip {
			width: calc(50% - 43px);
		}

		.options {
			width: 40px;
		}

		.status,
		.created,
		.protocol {
			width: calc(50% / 3);
		}
	}

	.orders-list-items {
		height: 100%;
		position: relative;
		margin-top: 5px;
		margin-bottom: 130px;
		overflow-y: scroll;
		overflow-x: hidden;

		.orders-status-label {
			display: none;
			justify-content: center;
			align-items: center;
			width: 240px;
			margin: 0 auto;
		}

		.paginator-main-content {
			position: fixed;
			bottom: 0px;
			left: 0px;
			height: 85px;
			margin: 0px;
		}
	}

	.loading-main-content,
	.has-error-content,
	.empty-main-content {
		position: relative;
		width: 100% !important;
		height: 100% !important;
		top: 0px !important;
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.orders-list-content {
		margin-top: 15px;

		.orders-status-label,
		.orders-list-header {
			display: none;
		}

		.orders-list-items {
			position: relative;
			margin-top: 0px;
			margin-bottom: 55px;
			overflow-y: scroll;
			overflow-x: hidden;

			.orders-status-label {
				display: flex;
				height: 40px;
				margin: -8px auto 0px;
			}

			.paginator-main-content {
				position: unset;
				bottom: unset;
				left: unset;
			}
		}
	}
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.orders-list-content {
		margin-top: 5px;

		.orders-status-label {
			margin: 0px auto 5px;
			display: flex;
		}

		.orders-list-header {
			display: none;
		}

		.orders-list-items {
			position: relative;
			margin-top: 0px;
			margin-bottom: 110px;
			overflow-y: scroll;
			overflow-x: hidden;

			.paginator-main-content {
				bottom: 50px;
				height: 65px;
			}
		}
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
	/* For portrait layouts only */

	.orders-list-content {
		margin-top: 20px;
		.orders-status-label {
			display: none;
		}
	}
}

@media screen and (max-width: 950px) {
	.orders-list-content {
		.orders-list-header {
			.equip {
				width: calc(45% - 40px);
			}

			.status,
			.created,
			.protocol {
				width: calc(55% / 3);
			}
		}
	}
}

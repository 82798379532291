.version-area-content {
	width: 100%;
	position: absolute;
	bottom: 20px;
	left: 0;
	margin-top: 20px;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: default;

	.update-area-content {
		span {
			text-align: center;
			font-size: 10px;
			font-weight: 400;
			letter-spacing: 0.18px;
			opacity: 0.5;
		}
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.version-area-content {
		position: relative;
		bottom: unset;
		left: unset;
	}
}

.empty-user-main-content {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: inherit;

	.empty-user-main {
		width: 650px;
		height: 45%;
		max-height: 380px;
		max-width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: row;

		img {
			max-width: calc(50% - 25px);
			height: 100%;
		}

		.empty-user-message {
			max-width: calc(50% - 25px);
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			h4 {
				font-size: 20px;
				font-weight: 500;
				letter-spacing: 0.3px;
				margin-bottom: 20px;
			}
			p {
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 0.21px;
			}

			.empty-user-actions {
				width: 100%;
				height: 45px;
				margin-top: 25px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				div {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					margin-right: 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					.MuiSvgIcon-root {
						width: 26px;
						height: 26px;
					}
				}

				span {
					text-align: left;
					text-decoration: underline;
					font-size: 12px;
					font-weight: 400;
					letter-spacing: 0.18px;
					text-transform: capitalize;
					cursor: pointer;
				}
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.empty-user-main-content {
		.empty-user-main {
			width: 550px;
			height: 65%;
		}
	}
}

@media screen and (max-width: 375px) {
	.empty-user-main-content {
		height: calc(100% - 200px) !important;

		.empty-user-main {
			img {
				max-width: 100px;
			}

			.empty-user-actions {
				margin-top: 0px;
			}
		}
	}
}

@media screen and (max-device-width: 480px) {
	.empty-user-main-content {
		height: calc(100% - 150px);
	}
}

@media screen and (max-width: 900px) {
	.empty-user-main-content {
		.empty-user-main {
			height: 80%;
			align-items: center;
			flex-direction: column;

			img {
				width: 100%;
				height: auto;
				margin-bottom: 25px;
			}

			.empty-user-message {
				max-width: calc(100% - 50px);
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				text-align: center;

				.empty-user-actions {
					justify-content: center;
				}
			}
		}
	}
}

.notify-center-main-content {
	position: absolute;
	top: 70px;
	width: 400px;
	height: 600px;
	border-radius: 9px;
	box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.15);
	z-index: 35;
	.notify-center-header {
		position: relative;
		height: 60px;
		font-size: 20px;
		font-weight: 600;
		border-radius: 6px 6px 0 0;
		span {
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0.24px;
			text-transform: uppercase;
		}
		.notify-center-close {
			position: absolute;
			width: 20px;
			height: 20px;
			border-radius: 20px;
			top: 18px;
			right: 18px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			.MuiSvgIcon-root {
				width: 16px;
				height: 16px;
			}
		}
	}
	.notify-center-option,
	.notify-center-header,
	.notify-center-footer {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.notify-center-option,
	.notify-center-footer {
		font-size: 12px;
		font-weight: 500;
		text-align: center;
		cursor: pointer;
	}
	.notify-center-footer {
		height: 50px;
		border-radius: 0 0 6px 6px;
		span {
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 0.2px;
			text-transform: uppercase;
		}
	}
	.notify-center-option {
		height: 50px;
		border-radius: 0 0 6px 6px;
		span {
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 0.2px;
			text-transform: uppercase;
		}
	}
	.notify-center-option.disabled {
		cursor: default;
		span {
			opacity: 0.5;
		}
	}
	.notify-center-content {
		height: calc(100% - 160px);
		position: relative;
		overflow-y: auto;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		.empty-main-content {
			width: 100%;
			padding-left: 15px;
			padding-right: 15px;
			span {
				font-size: 15px;
			}
		}
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
	/* For portrait layouts only */

	.notify-center-main-content {
		height: 425px;
	}
}

.equip-orders-list-mobile-content {
	.equip-orders-title {
		width: 100%;
		height: 30px;
		text-align: left;
		padding: 0px 20px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 15px;

		span {
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 0.21px;
		}
	}

	.equip-orders-list-header {
		display: flex;
		align-content: center;
		justify-content: flex-start;
		flex-direction: row;
		width: 100%;
		height: 25px;
		padding: 0px 20px;
		.list-header {
			display: flex;
			align-content: center;
			justify-content: flex-start;
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 0.21px;

			.MuiSvgIcon-root {
				width: 15px;
				height: 15px;
				margin-top: 2px;
				margin-left: 5px;
				cursor: pointer;
			}
		}

		.created {
			width: 220px;
		}

		.protocol {
			width: 170px;
		}

		.status {
			width: 140px;
		}

		.tech {
			width: calc(100% - (220px + 170px + 140px));
		}
	}

	.equip-orders-list-items {
		width: 100%;
		height: calc(100% - 30px);
		overflow-y: auto;
		overflow-x: hidden;
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.equip-orders-list-mobile-content {
		.equip-orders-list-header {
			padding: 0 5px 0 20px;

			.created,
			.protocol,
			.status {
				width: calc((100% - 40px) / 3);
			}

			.tech {
				display: none;
			}
		}
	}
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.equip-orders-list-mobile-content {
		.equip-orders-list-header {
			.created {
				width: 150px;
			}

			.protocol {
				width: 150px;
			}

			.status {
				width: 140px;
			}

			.tech {
				width: calc(100% - 480px);
			}
		}

		.equip-orders-list-items {
			width: 100%;
			height: calc(100% - 30px);
			overflow-y: auto;
			overflow-x: hidden;
		}
	}

	.equip-orders-list-mobile-content.shadow-on-scroll {
		.equip-orders-list-header {
			width: 100%;
			z-index: 1;
			position: relative;
		}

		.equip-orders-list-mobile-items {
			margin-top: 0px !important;
		}
	}
}

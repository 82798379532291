.notify-center-content {
	.notify-list-item-mobile {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;

		.list-item-row {
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: row;

			.list-item {
				display: flex;
				flex-direction: row;
				margin-bottom: 10px;
			}

			.title {
				width: calc(100% - 50px);
				height: 70px;
				padding: 15px 20px 0;
				align-items: flex-start;
				justify-content: flex-start;

				span {
					font-size: 12px;
					font-weight: 400;
					letter-spacing: 0.18px;
					opacity: 0.7;
				}

				.not-read {
					font-weight: 500;
					opacity: 1;
				}
			}

			.notify-item-menu {
				width: 50px;
				height: 70px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.options {
				width: 100%;
				padding: 0px 20px;
				align-items: center;
				justify-content: space-between;

				.mark-as,
				.unmark-as {
					cursor: pointer;
					font-size: 12px;
					font-weight: 500;
					letter-spacing: 0.18px;
				}

				.mark-as {
					text-decoration: underline;
				}

				.notify-time {
					font-size: 12px;
					font-weight: 300;
					letter-spacing: 0.18px;
				}
			}
		}
	}
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */

	.notify-center-content {
		.notify-list-item-mobile {
			.list-item-row {
				.title,
				.notify-item-menu {
					height: 40px;
				}

				.options {
					height: 20px;
				}
			}
		}
	}
}

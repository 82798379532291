.app-settings-main-content {
	position: relative;
	.app-settings-item {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;

		label {
			margin: 10px;
		}
	}

	.app-settings-actions {
		margin-top: 20px;
	}
}

.service-order-timeline-content {
	padding: 25px 15px;
	height: 100%;

	.service-order-timeline-list-content {
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		flex-direction: column;

		.service-order-timeline-item {
			width: 100%;
			height: auto;
			min-height: 110px;
			display: flex;
			align-items: stretch;
			justify-content: stretch;
			flex-direction: row;

			.service-order-timeline-description {
				width: calc(100% - 55px);
			}
		}
	}
}

@media screen and (max-width: 900px) {
	.service-order-timeline-content {
		padding: 20px 0px;
		width: 100% !important;
		overflow-y: auto;
		height: 100%;

		.service-order-timeline-list-content {
			padding: 0px 10px 50px;
			height: auto;

			.service-order-timeline-item {
				.service-order-timeline-description {
					width: calc(100% - 30px);
				}
			}
		}

		.modal-main-backdrop {
			top: 0px !important;
		}
	}
}

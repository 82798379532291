@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap');
:root {
	--app-height: 100%;
	--app-bar-height: 100%;
}

html,
body {
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.main-page-content {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	overflow: hidden;
}

.main-web-menu {
	height: 100px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	z-index: 10;
	position: fixed;
}

.main-mobile-menu {
	display: none;
	height: 50px;
	width: 100%;
	position: fixed;
	bottom: 0px;
}

.main-page-component {
	background-color: #f8f8f8;
	width: 100%;
	height: calc(100vh - 100px);
	margin-top: 100px;
	overflow: hidden;
}

.main-mobile-menu-options,
.mobile-help-main-content,
.mobile-settings-main-content,
.client-area-selector.mobile,
.notify-center-mobile-content,
.modal-main-backdrop,
.main-page-content {
	-webkit-transition: height 15ms ease-in-out;
	-moz-transition: height 15ms ease-in-out;
	-ms-transition: height 15ms ease-in-out;
	-o-transition: height 15ms ease-in-out;
	transition: height 15ms ease-in-out;
}

.main-page-content.light-theme,
.main-page-content.dark-theme,
.main-page-content.changing-theme {
	transition: filter ease-in 500ms;
}

.main-page-content.changing-theme {
	filter: grayscale(100%);
}

a,
p,
b,
span,
h1,
h2,
h3,
h4,
h5,
h6,
small,
input,
textarea,
input::placeholder,
textarea::placeholder,
label {
	font-family: 'Roboto', sans-serif;
}

input.MuiOutlinedInput-input::placeholder {
	font-weight: 500;
	letter-spacing: 0.2px;
}

.main-form-content {
	width: 100%;
	.form-item-content {
		height: 90px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		label {
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0px;
			margin-bottom: 8px;
		}
		input,
		textarea {
			width: 100%;
			height: 45px;
			border: none;
			border-radius: 6px;
			padding: 10px;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
			resize: none;
			font-weight: bold;
		}
		textarea {
			height: 120px;
		}
		input::placeholder,
		textarea::placeholder {
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
		}
		input:focus-visible,
		textarea:focus-visible {
			outline: none;
			box-shadow: none;
		}
		input[type='password'] {
			font: small-caption;
			font-size: 15px;
		}
		.toggle-input {
			width: 100%;
			position: relative;
			div {
				position: absolute;
				top: 10px;
				right: 15px;
				color: #333333;
				opacity: 0.2;
				cursor: pointer;
			}
		}
		.dropdown-content .MuiInputBase-root {
			width: 100%;
		}
		.form-item-content-validator {
			text-align: left;
			span {
				font-size: 10px;
				font-weight: 400;
				letter-spacing: 0.18px;
			}
		}
		.MuiFormControl-root {
			width: 100% !important;
			padding: 0px;

			.MuiIconButton-root,
			.PrivateSwitchBase-root-5 {
				padding: 0px;
			}
		}
		.MuiIconButton-label {
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
				border: 1px solid;
				.MuiSvgIcon-root:first-child {
					width: 16px;
					height: 16px;
					padding: 0px;
					opacity: 0;
				}
			}
		}
		.Mui-checked {
			.MuiIconButton-label {
				div {
					.MuiSvgIcon-root:last-child {
						transform: scale(1.3);
						left: unset;
					}
				}
			}
		}
		.MuiFormControlLabel-root {
			height: 40px;
			margin: 0px;
		}
		.MuiTypography-body1 {
			text-align: left;
			font-size: 120px;
			letter-spacing: 0.18px;
			text-transform: capitalize;
			font-family: Roboto, sans-serif;
			font-weight: 400;
			margin-left: 5px;
		}
		.custom-form-item-content {
			.custom-form-item-input.input-has-error,
			.custom-form-item-input.input-has-error.email {
				input {
					-webkit-box-shadow: 0px 0px 3px 0px rgba(194, 22, 24, 0.5);
					box-shadow: 0px 0px 3px 0px rgba(194, 22, 24, 0.5);
					border-color: #c21618;
					background-color: #ffe5e5 !important;
				}
			}
			.custom-form-item-content-validator {
				background-color: #ffe5e5;
				border-color: #c21618;
				-webkit-box-shadow: 0px 0px 3px 0px rgba(194, 22, 24, 0.5);
				box-shadow: 0px 0px 3px 0px rgba(194, 22, 24, 0.5);
				span {
					color: #333333;
				}
			}
		}
	}
	.form-content-validator {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 15px 0;
		.form-item-content-validator {
			text-align: left;
			span {
				font-size: 10px;
				font-weight: 400;
				letter-spacing: 0.18px;
			}
		}
	}
}

.login-main-content,
.create-account-main-content,
.new-pass-main-content,
.reset-pass-main-content {
	.side-bar-content {
		background-color: #004687;
		color: #f8f9fa;

		::-webkit-scrollbar-track {
			background-color: #004687;
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			background-color: #d0dff0;
		}

		/* Handle on hover */
		::-webkit-scrollbar-thumb:hover {
			background-color: #5daeff;
		}
	}
	.login-form,
	.new-pass-form,
	.create-account-form,
	.reset-pass-form {
		.main-form-content {
			.form-item-content {
				input {
					color: #333333;
					font-weight: 400;
				}
				input::placeholder {
					color: #bebebe;
				}
				input:disabled {
					background-color: #c9c9c9;
					color: #969696;
					opacity: 1;
				}
				input:disabled::placeholder {
					color: #969696;
				}
				.toggle-input {
					div {
						color: #333333 !important;
					}
				}
			}
		}
		.loading-main-content {
			background-color: #004687;
			.loading-main,
			.loading-main svg {
				color: #f8f9fa !important;
			}
		}
		.login-form-options {
			a,
			a:hover,
			a:visited {
				color: #f8f9fa;
			}
		}
		.create-account-form-actions {
			button {
				background-color: #004687;
				color: #f8f9fa;
				border: 1px solid #f8f9fa;
			}
		}
		.login-form-actions,
		.new-pass-form-actions,
		.reset-pass-form-actions {
			.login-submit,
			.new-pass-submit,
			.create-account-submit,
			.reset-pass-submit {
				background-color: #f8f9fa;
				color: #004687;
				border: 1px solid #f8f9fa;
			}
			.reset-pass-cancel,
			.login-create-account {
				border: 1px solid #f8f9fa;
				background-color: #004687;
				color: #f8f9fa;
			}
			.login-submit:focus-visible,
			.create-account-submit:focus-visible,
			.new-pass-submit:focus-visible,
			.reset-pass-submit:focus-visible,
			.reset-pass-cancel:focus-visible,
			.login-create-account:focus-visible {
				outline: none;
				box-shadow: none;
			}
		}
		.create-account-options {
			.MuiCheckbox-root,
			.MuiCheckbox-colorSecondary.Mui-checked {
				color: #f8f9fa;
			}
		}
	}
}

#accounts-forms {
	/* Track */
	&::-webkit-scrollbar-track {
		background: #0b5591;
		border-radius: 0px;
	}
	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #f8f9fa;
	}
	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #f8f9fa;
	}
}

.Toastify {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 100%;
}

.Toastify__toast-container--top-center {
	left: unset;
	margin-left: 0;
}

.toast-main-content {
	top: -4px;
	width: auto !important;
	max-width: 320px;
	text-align: center;
	.Toastify__toast {
		min-height: 40px;
		border-radius: 0px 0px 6px 6px;
		padding: 8px 20px;
	}
	.toast-body-content {
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 0.2px;
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */

	html,
	body {
		height: 100vh;
		overflow: hidden !important;
	}

	html:has(body.outside-page),
	body.outside-page {
		height: var(--app-height);
		position: fixed;
		overflow: hidden !important;
		background-color: #004687;
	}

	.main-web-menu {
		display: none;
	}

	.main-mobile-menu {
		display: block;
	}

	.main-page-content {
		position: fixed;
		width: 100%;
	}

	.main-page-component {
		height: calc(var(--app-height) - 50px);
		margin-top: 0;
	}

	.main-page-component.root-page {
		height: calc(var(--app-height) - 50px);
		padding-bottom: 50px;
		margin-top: 70px;
	}

	.login-main-content,
	.create-account-main-content,
	.new-pass-main-content,
	.reset-pass-main-content {
		height: var(--app-height);
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */

	.main-web-menu {
		display: none;
	}

	.main-mobile-menu {
		display: flex;
	}

	.main-page-component {
		height: calc(var(--app-height) - 50px);
		margin-top: 0;
	}

	.main-page-component.root-page {
		height: calc(var(--app-height) - 50px);
		padding-bottom: 50px;
		margin-top: 70px;
	}

	.login-main-content,
	.create-account-main-content,
	.new-pass-main-content,
	.reset-pass-main-content {
		height: var(--app-height);
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
	/* For portrait layouts only */

	.main-page-content {
		height: calc(var(--app-height));
	}
}

@media screen and (max-width: 900px) {
	p,
	b,
	span,
	span b,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	small,
	input,
	button,
	textarea,
	input::placeholder,
	textarea::placeholder,
	label {
		font-family: 'Roboto', sans-serif !important;
	}
	.main-web-menu {
		display: none;
	}
	.main-mobile-menu {
		display: flex;
	}
	.main-page-selector {
		position: fixed;
		top: 0px;
		width: 100%;
		padding-right: 20px;
	}
	.go-back-component {
		justify-content: center;
		span {
			display: none;
		}
	}
	.modal-main-backdrop {
		height: calc(var(--app-height) - 48px);
		overflow-x: hidden;
	}
	.toast-main-content {
		max-width: 320px;
	}
	.Toastify__toast-container {
		margin: 0px;
		left: unset;
	}
}

.MuiPickersModal-dialogRoot,
.MuiPickersModal-dialog {
	border-radius: 6px;
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar,
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
	border-radius: 6px;
}

.version-area {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	position: absolute;
	top: -10px;
	left: 10px;

	span {
		color: #f8f9fa;
		font-size: 10px;
		font-weight: 500;
		letter-spacing: 0.2px;
	}
}

@import url('./theme/light/colors.scss');
@import url('./theme/light/fonts.scss');
@import url('./theme/dark/colors.scss');
@import url('./theme/dark/fonts.scss');
@import url('./zendesk-widget.scss');

.renew-content {
	max-width: 25rem;
}

.renew-pass-form {
	width: 100%;
	height: auto;
	position: relative;

	.main-form-content {
		.form-item-content {
			margin-bottom: 5px;

			label {
				font-size: 12px;
				font-weight: 500;
				letter-spacing: 0px;
				margin-bottom: 8px;
			}

			input {
				height: 45px;
			}
		}
	}

	.loading-main-content {
		width: calc(100% + 20px);
		height: calc(100% + 20px);
		position: relative;
		margin: -10px;
	}

	.renew-pass-options {
		margin-top: 10px;
		height: 20px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		span {
			font-size: 10px;
			font-weight: 400;
			letter-spacing: 0.18px;
		}
	}

	.renew-pass-form-actions {
		margin-top: 40px;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;

		button {
			width: calc(50% - 20px);
			height: 36px;
			border-radius: 6px;
			border: none;
			letter-spacing: 0.2px;
			font-size: 13px;
			font-weight: 500;
			cursor: pointer;
		}

		.button:focus-visible {
			outline: none;
			box-shadow: none;
		}
	}

	.response-modal-web,
	.response-modal-mobile {
		.modal-main-content {
			text-align: center;
		}
	}
}

.service-orders-files-center-content {
	width: 480px;
	max-width: 100%;
	height: auto;
	font-size: 12px;

	.files-center-description-content {
		width: 100%;
		margin-bottom: 20px;

		h5 {
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 0.21px;
		}
	}

	.files-center-header-content {
		width: 100%;
		height: 30px;
		display: flex;
		align-content: center;
		justify-content: flex-start;
		flex-direction: row;

		.list-header {
			display: flex;
			align-content: center;
			justify-content: flex-start;
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 0.2px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			span {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}

		.name {
			width: 50%;
		}

		.type,
		.created {
			width: 25%;
		}
	}

	.files-center-list-content {
		width: calc(100% + 40px);
		height: 210px;
		margin-left: -20px;
		margin-right: -40px;
		max-height: 300px;
		margin-bottom: 20px;
		overflow-y: auto;

		.files-center-list-item-content {
			height: 30px;
			display: flex;
			align-content: center;
			justify-content: flex-start;
			flex-direction: row;
			cursor: pointer;
			padding-left: 20px;
			padding-right: 15px;

			.list-item {
				height: 100%;
				display: flex;
				align-items: center;
				text-align: left;
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.18px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				padding-right: 5px;

				span {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			.name {
				width: 50%;
			}

			.type,
			.created {
				width: 25%;
			}
		}
	}
}

@media screen and (max-width: 900px) {
	.service-orders-files-center-content {
		.files-center-list-content {
			width: calc(100% + 40px);
			height: auto;
			max-height: unset;
			overflow-y: auto;
		}
	}
}

.client-selector-list {
	width: 100%;
	height: 100%;
	overflow: auto;

	.MuiFormControl-root {
		width: 100%;
		padding: 0px;

		.MuiIconButton-root,
		.PrivateSwitchBase-root-5 {
			padding: 0px;
		}
	}

	.MuiIconButton-label {
		div {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			border: 1px solid;

			.MuiSvgIcon-root:first-child {
				width: 16px;
				height: 16px;
				padding: 0px;
				opacity: 0;
			}
		}
	}

	.Mui-checked {
		.MuiIconButton-label {
			div {
				.MuiSvgIcon-root:last-child {
					transform: scale(1.3);
					left: unset;
				}
			}
		}
	}

	.MuiFormControlLabel-root {
		height: 40px;
		margin: 0px;
	}

	.MuiTypography-body1 {
		text-align: left;
		font-size: 12px;
		letter-spacing: 0.18px;
		text-transform: uppercase;
		font-family: Roboto, sans-serif;
		font-weight: 400;
		margin-left: 5px;
	}
}

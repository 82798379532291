.equip-list-content {
	margin-top: 20px;
	height: 100%;
	display: flex;
	flex-direction: column;

	.equip-list-header {
		display: flex;
		align-content: center;
		justify-content: flex-start;
		flex-direction: row;
		width: calc(100% - 55px);
		height: 30px;
		padding: 0px 20px;

		.list-header {
			display: flex;
			align-content: center;
			justify-content: flex-start;
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 0.2px;

			.MuiSvgIcon-root {
				width: 15px;
				height: 15px;
				margin-top: 2px;
				margin-left: 5px;
				cursor: pointer;
			}
		}

		.equip {
			width: 50%;
		}

		.warrant,
		.serial {
			width: 25%;
		}
	}

	.equip-list-items {
		height: 100%;
		position: relative;
		margin-top: 5px;
		margin-bottom: 130px;
		overflow-y: scroll;
		overflow-x: hidden;

		.paginator-main-content {
			position: fixed;
			bottom: 0px;
			left: 0px;
			height: 85px;
			margin: 0px;
		}
	}

	.loading-main-content,
	.has-error-content,
	.empty-main-content {
		position: relative;
	}
}

@media screen and (max-width: 1300px) {
	.equip-list-content {
		.equip-list-header {
			.equip {
				width: 65%;
			}

			.warrant,
			.serial {
				width: 17.5%;
			}
		}
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.equip-list-content {
		margin-top: 20px;

		.equip-list-header {
			display: none;
		}

		.equip-list-items {
			position: relative;
			margin-top: 0px;
			margin-bottom: 60px;
			overflow-y: scroll;
			overflow-x: hidden;

			.paginator-main-content {
				position: unset;
				bottom: unset;
				left: unset;
			}
		}
	}
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.equip-list-content {
		margin-top: 20px;

		.equip-list-header {
			display: none;
		}

		.equip-list-items {
			position: relative;
			margin-top: 0px;
			margin-bottom: 145px;
			overflow-y: scroll;
			overflow-x: hidden;

			.paginator-main-content {
				bottom: 50px;
				height: 65px;
			}
		}
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
	/* For portrait layouts only */
}

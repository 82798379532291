.main-web-menu {
	padding: 0px 20px;
	.logo-pratica,
	.web-navigator-component,
	.user-client-options-item,
	.user-area {
		width: 33.33%;
		height: 30px;
	}
	.web-navigator-component,
	.logo-pratica {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
	}
	.web-navigator-component {
		cursor: pointer;
		text-transform: uppercase;
		.MuiSvgIcon-root {
			width: 17px;
			height: 17px;
			margin-right: 5px;
		}
	}
	.user-client-options-item {
		display: flex;
		align-items: center;
		justify-content: center;
		span {
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0.24px;
			text-transform: uppercase;
		}
	}
	.user-area {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.user-notifications-area {
			width: 50px;
			cursor: pointer;
			position: relative;
			.user-notifications-badge {
				height: 13px;
				width: 13px;
				border-radius: 100%;
				position: absolute;
				top: 0px;
				right: 10px;
				cursor: pointer;
			}
			.user-notifications-badge.hide {
				display: none;
			}
		}
	}
	.dropdown-area {
		max-width: 230px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		cursor: pointer;
		span {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	.user-name,
	.client-name {
		text-transform: uppercase;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-o-user-select: none;
	}
}

.notifications-center-backdrop {
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	backdrop-filter: blur(5px);
	z-index: 35;
}

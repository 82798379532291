.login-form {
	width: 100%;
	height: auto;
	position: relative;

	.main-form-content {
		.form-item-content {
			margin-bottom: 5px;

			label {
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0px;
				margin-bottom: 8px;
			}

			input {
				height: 45px;
				font-weight: 400;
			}
		}
	}

	.loading-main-content {
		width: calc(100% + 20px);
		height: calc(100% + 20px);
		position: absolute;
		margin: -10px;
	}

	.login-form-options {
		margin-top: 5px;
		height: 60px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: row-reverse;

		a {
			text-decoration: underline;
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 0.18px;
		}
	}

	.login-form-actions {
		margin-top: 50px;

		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;

		button {
			width: calc(50% - 10px);
			height: 36px;
			border-radius: 6px;
			border: none;
			letter-spacing: 0.2px;
			font-size: 13px;
			font-weight: 500;
			cursor: pointer;
		}

		.button:focus-visible {
			outline: none;
			box-shadow: none;
		}
	}
}

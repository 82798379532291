.client-area-selector {
	width: 360px;
	height: 515px;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 23px;
	padding: 15px 20px 15px;

	position: absolute;
	left: calc(50% - 180px);
	top: 60px;
	z-index: 35;

	.client-area-selector-header span {
		font-size: 14px;
		letter-spacing: 0.21px;
		font-weight: 500;
	}

	.client-area-search {
		margin-top: 11px;
	}

	.client-area-list {
		margin-top: 10px;
		height: calc(100% - 90px);
		position: relative;
	}

	.client-area-list.show-stores {
		height: calc(100% - 120px);
	}

	.client-configs {
		width: 100%;
		height: 45px;
		bottom: 0;
		left: 0;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom-left-radius: 23px;
		border-bottom-right-radius: 23px;
		cursor: pointer;

		span {
			text-transform: uppercase;
			text-align: center;
			font-size: 13px;
			letter-spacing: 0.2px;
		}
	}
}

.client-area-selector.mobile {
	width: 100%;
	height: calc(var(--app-height) - 48px);
	box-shadow: none;
	border-radius: 0;

	position: absolute;
	left: 0;
	top: 0;
	z-index: 35;

	.client-configs {
		border-radius: 0px;
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
	/* For portrait layouts only */

	.client-area-selector {
		height: 425px;
	}
}

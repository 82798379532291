.equip-orders-list-content {
	position: relative;
	width: 100%;
	padding-top: 79px;

	.equip-orders-list-header {
		display: flex;
		align-content: center;
		justify-content: flex-start;
		flex-direction: row;
		width: 100%;
		height: 25px;
		padding: 0px 60px;

		.list-header {
			display: flex;
			align-content: center;
			justify-content: flex-start;
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 0.2px;

			.MuiSvgIcon-root {
				width: 15px;
				height: 15px;
				margin-top: 2px;
				margin-left: 5px;
				cursor: pointer;
			}
		}

		.created {
			width: 220px;
		}

		.protocol {
			width: 170px;
		}

		.status {
			width: 140px;
		}

		.options {
			width: 40px;
		}

		.tech {
			width: calc(100% - (220px + 170px + 145px + 40px));
		}
	}

	.equip-orders-list-items {
		width: 100%;
		height: calc(100% - 50px);
		overflow: auto;
		position: relative;
	}

	.paginator-main-content {
		bottom: 0px;
		left: 0px;
		height: 85px;
		margin: 0px;
	}

	.loading-main-content,
	.has-error-content,
	.empty-main-content {
		position: relative;
		width: 100% !important;
		height: 100% !important;
		top: 0px !important;
	}
}

.equip-orders-list-content.shadow-on-scroll {
	.equip-orders-list-header {
		z-index: 1;
		position: relative;
	}

	.equip-orders-list-items {
		margin-top: 0px !important;
	}
}

@media screen and (max-width: 1400px) {
	.equip-orders-list-content {
		padding-top: 52px;
	}
}

@media screen and (max-width: 1200px) {
	.equip-orders-list-content {
		.equip-orders-list-header {
			padding: 0px 25px 0px 25px;

			.created {
				width: 180px;
			}

			.status,
			.protocol {
				width: 120px;
			}

			.tech {
				width: calc(100% - (180px + 245px + 30px));
			}
		}
	}
}

.service-order-tech-files {
    width: 320px;
    height: auto;
    border-radius: 6px;
    box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.15);
    z-index: 30;

    .tech-files-header,
    .tech-files-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
    }

    .tech-files-list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 15px;

        div {
            height: 30px;
            width: 100%;
        }

        span {
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.18px;
            cursor: pointer;
        }
    }

    .tech-files-header {
        border-radius: 6px 6px 0 0;
        justify-content: flex-start;
        height: 40px;

        span {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.18px;
        }
    }
}

.service-order-timeline-file-link {
    margin-bottom: 25px;

    div {
        width: 50px;
        height: 15px;
        background-color: transparent;
        box-shadow: 0px 0px 5px #3333334D;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-transition: all 150ms ease-in-out;
        -moz-transition: all 150ms ease-in-out;
        -ms-transition: all 150ms ease-in-out;
        -o-transition: all 150ms ease-in-out;
        transition: all 150ms ease-in-out;
        cursor: pointer;

        span {
            font-size: 10px;
            font-weight: 400;
            letter-spacing: 0.18px;
            -webkit-transition: all 250ms ease-in-out;
            -moz-transition: all 250ms ease-in-out;
            -ms-transition: all 250ms ease-in-out;
            -o-transition: all 250ms ease-in-out;
            transition: all 250ms ease-in-out;
        }
    }
}
.empty-main-content {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;

	.empty-main {
		width: 500px;
		max-width: 100%;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		img {
			max-width: calc(100% - 40px);
		}

		span {
			margin-top: 40px;
			text-transform: uppercase;
			letter-spacing: 0.3px;
			font-size: 20px;
			font-weight: 500;
			opacity: 0.6;
		}
	}
}

@media screen and (max-width: 900px) {
	.empty-main-content {
		.empty-main {
			img {
				max-width: calc(100% - 60px);
			}

			span {
				margin-top: 30px;
				font-size: 14px;
			}
		}
	}
}

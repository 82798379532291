.open-service-order-content {
	width: 650px !important;

	.form-item-content{
		textarea{
			font-weight: 400 !important;
		}
	}

	.characters-remaining{
		font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.18px;
	}
	
	.service-order-address-content {
		padding: 0px 2px;
		margin-bottom: 20px;
		overflow-y: scroll;
		height: 380px;

		.service-order-equip-name {
			margin: 10px 0;

			span {
				text-align: left;
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 0.21px;
				text-transform: uppercase;
			}
		}

		.service-order-address-selector {
			margin: 10px 0;

			.MuiFormControl-root {
				width: 100%;
				padding: 0px;

				.MuiIconButton-root,
				.PrivateSwitchBase-root-5 {
					padding: 0px;
				}
			}

			.MuiIconButton-label {
				div {
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 100%;
					border: 1px solid;

					.MuiSvgIcon-root:first-child {
						width: 16px;
						height: 16px;
						padding: 0px;
						opacity: 0;
					}
				}
			}

			.Mui-checked {
				.MuiIconButton-label {
					div {
						.MuiSvgIcon-root:last-child {
							transform: scale(1.3);
							left: unset;
						}
					}
				}
			}

			.MuiFormControlLabel-root {
				height: 40px;
				margin: 0px;
			}

			.MuiTypography-body1 {
				text-align: left;
				font-size: 12px;
				letter-spacing: 0.18px;
				text-transform: capitalize;
				font-family: Roboto, sans-serif;
				font-weight: 400;
				margin-left: 5px;
			}
		}

		.message {
			height: auto;
		}

		.form-content-validator {
			.form-item-content-validator {
				width: 50%;
			}
		}
	}

	.open-service-actions {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

@media screen and (max-width: 480px) and (max-height: 980px) and (orientation: portrait) {
	.open-service-order-content {
		width: 100% !important;
		height: 100%;
		overflow-y: unset;

		.main-form-content {
			height: 100%;
		}

		.service-order-address-content {
			padding: 0px 2px;
			margin-bottom: 20px;
			min-height: calc(100% - 70px);

			.service-order-equip-name {
				display: none;
			}
		}

		.open-service-actions {
			padding-bottom: 20px;
		}
	}
}

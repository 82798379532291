.orders-status-label {
	max-width: 250px;
	width: 100%;
	height: 40px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;

	.status-label {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;

		span {
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0.18px;
			opacity: 1;
		}
	}
}

.equip-add-main-content {
	width: 440px;
	max-width: 100%;

	h4 {
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.26px;
		margin: 0 0 20px 0;
	}

	.main-form-content {
		.form-row-content {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.form-item-content {
				width: calc(50% - 10px);
			}
		}
	}

	.equip-add-actions {
		margin-top: 25px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.second-button-group {
			display: flex;
			justify-items: space-between;
			width: 100%;
			margin-left: -20px;

			.custom-button-primary.outline-button {
				width: 100%;
			}
			.custom-button-secondary {
				width: 100%;
				margin-right: -10px;
			}
		}
	}

	.equip-add-conflict-message {
		border-radius: 5px;
		padding: 10px 20px;
		text-align: center;

		span {
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 0.26px;
		}
	}

	.equip-add-success-message {
		width: 440px;
		text-align: center;

		h4 {
			margin: 0px 0px 15px;
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 0.26px;
		}

		span {
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 0.26px;
		}
	}
}

@media screen and (max-width: 900px) {
	.equip-add-main-content {
		height: calc(100% - 20px);
		.main-form-content {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.form-row-content {
				justify-content: flex-start;
				flex-direction: column;

				.form-item-content {
					width: 100%;
				}
			}
		}
	}
	.equip-add-success-message {
		text-align: center;
		padding: 0px 20px 0px 20px;
		font-size: 12px;
		
		h4,
		span {
			text-align: center;
			font-size: 12px !important;
			font-weight: 400;
			letter-spacing: 0.26px;
		}
	}
}

.orders-filter-content {
	width: 100%;
	max-height: 480px;
	position: relative;

	@media screen and (max-width: 479px) {
		.clear-filter {
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
			margin-top: -45px;
			span {
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.18px;
			}
		}
	}

	.clear-filter {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;

		span {
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
		}
	}

	.period-filter,
	.status-filter,
	.defect-filter {
		height: auto;
		min-height: 70px;
		margin-bottom: 15px;

		span {
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.2px;
		}
	}

	.period-filter {
		.period-filter-header {
			width: 100%;
			margin-bottom: 5px;

			span {
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.2px;
			}
		}

		.period-filter-fields {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.MuiFormControl-root.MuiTextField-root {
				width: 50%;
			}

			.MuiFormControl-root.MuiTextField-root:first-child {
				margin-right: 20px;
			}

			.MuiFormLabel-root {
				transform: translate(0, 1.5px) scale(1);
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.2px;
				font-family: 'Roboto', sans-serif;
				opacity: 1;
			}

			.MuiInputBase-root {
				border-radius: 6px;
				padding-left: 10px;
				font-family: 'Roboto', sans-serif;
				letter-spacing: 0.18px;
				font-size: 12px;
				height: 40px;
			}

			.MuiInput-underline:hover,
			.MuiInput-underline:hover:not(.Mui-disabled):before,
			.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
			.MuiInput-underline::before,
			.MuiInput-underline::after {
				border: none;
				outline: none !important;
				content: '' !important;
				box-shadow: none !important;
			}

			.MuiIconButton-root {
				padding: 8px;
			}

			.MuiFormHelperText-root.Mui-error {
				font-family: 'Roboto', sans-serif;
				letter-spacing: 0.18px;
				font-size: 11px;
			}
		}
	}

	.status-filter {
		.status-filter-header {
			width: 100%;
			margin-bottom: 5px;

			span {
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.2px;
			}
		}

		.status-filter-fields {
			.MuiFormGroup-root,
			.MuiFormControl-root {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-direction: row;
			}

			.MuiFormControlLabel-root {
				width: calc(50% - 16px);
			}

			.MuiTypography-body1 {
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.18px;
				font-family: 'Roboto', sans-serif;
				opacity: 1;
			}
		}
	}

	.defect-filter {
		.input-search-field input.MuiOutlinedInput-input {
			height: 23px !important;
		}
	}

	.orders-filter-actions {
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
	}
}

#defect-field-selector-popup,
#defect-field-selector-popup {
	padding: 0px;
	border-radius: 6px !important;
	box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.15) !important;

	.MuiAutocomplete-option {
		font-size: 12px !important;
		letter-spacing: 0.18px !important;
		line-height: 16px !important;
		text-transform: capitalize;
	}
}

.MuiDialog-root .MuiTypography-root,
.MuiDialog-root .MuiTypography-body2 {
	font-family: 'Roboto', sans-serif;
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.orders-filter-content {
		width: 100%;
		height: calc(var(--app-height) - 120px);
		max-height: unset;

		.orders-filter-actions {
			bottom: 0;
			position: absolute;
		}
	}
}

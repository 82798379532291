.create-account-main-content {
	width: 100vw;
	height: 100vh;
	position: relative;
}

@media screen and (max-width: 900px) {
}

/* different techniques for iPad screening */
@media only screen and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.create-account-main-content {
		height: var(--app-height);
		overflow-y: auto;

		.side-bar-content {
			height: auto;
		}
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
	/* For portrait layouts only */

	.create-account-main-content {
		height: var(--app-height);
	}
}

.stores-settings-header-content {
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.title-page-area {
		width: 33%;
		padding: 0px 20px;
		height: 40px;
		display: flex;
		align-items: center;

		h4 {
			font-size: 24px;
			font-weight: 600;
			letter-spacing: 0.36px;
			text-transform: uppercase;
			margin: 0px;
		}
	}

	.stores-view-type {
		margin-right: 35px;

		.MuiFormGroup-root {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: row;

			label {
				margin: 0px 10px;
			}
		}

		.MuiFormControl-root {
			width: 100%;
			padding: 0px;

			.MuiIconButton-root,
			.PrivateSwitchBase-root-5 {
				padding: 0px;
			}
		}

		.MuiIconButton-label {
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
				border: 1px solid;

				.MuiSvgIcon-root:first-child {
					width: 16px;
					height: 16px;
					padding: 0px;
					opacity: 0;
				}
			}
		}

		.Mui-checked {
			.MuiIconButton-label {
				div {
					.MuiSvgIcon-root:last-child {
						transform: scale(1.3);
						left: unset;
					}
				}
			}
		}

		.MuiFormControlLabel-root {
			height: 40px;
			margin: 0px;
		}

		.MuiTypography-body1 {
			text-align: left;
			font-size: 12px;
			letter-spacing: 0.18px;
			text-transform: capitalize;
			font-family: Roboto, sans-serif;
			font-weight: 400;
			margin-left: 5px;
		}
	}
}

@media screen and (max-width: 1200px) {
	.stores-settings-header-content {
		.title-page-area {
			width: 360px;
		}
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
	/* For portrait layouts only */

	.stores-settings-header-content {
		.stores-view-type {
			margin-right: 0px;
		}
		.page-list-options-content {
			width: 285px;
		}
	}
}

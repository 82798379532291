.custom-button-primary,
.custom-button-secondary {
	font-family: 'Roboto', sans-serif;
	width: 120px;
	height: 36px;
	border-radius: 6px;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0.22px;
	text-transform: uppercase;
	margin: 0 10px;
	border: none;
	cursor: pointer;
}

.custom-button-primary:disabled,
.custom-button-secondary:disabled {
	cursor: not-allowed;
	filter: grayscale(100);
}

.custom-button-primary.outline-button,
.custom-button-prisecondaryary.outline-button {
	border: 1px solid;
	background-color: transparent !important;
}

.orders-list-items {
	.list-item.web {
		width: 100%;
		height: 50px;
		padding: 0px 20px;
		display: flex;
		align-content: center;
		justify-content: space-between;
		flex-direction: row;
		cursor: pointer;

		.order-info {
			width: 100%;
			display: flex;
			align-content: center;
			justify-content: flex-start;
			flex-direction: row;

			div {
				height: 100%;
				display: flex;
				align-items: center;
				text-align: left;
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.18px;
				text-transform: uppercase;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;

				span {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			.equip {
				width: calc(50% - 40px);
			}

			.options {
				display: none !important;
				width: 40px;

				.more-options-button-service-orders {
					width: 100%;
				}
			}

			.status,
			.created,
			.protocol {
				width: calc(50% / 3);
			}
		}
	}

	.list-item.web:hover {
		.order-info {
			.options {
				display: flex !important;
			}
		}
	}

	.list-item.mobile {
		width: 100%;
		height: 70px;
		padding: 10px 20px;
		display: flex;
		align-content: center;
		justify-content: flex-start;
		flex-direction: row;
		cursor: pointer;

		.order-info {
			width: 100%;
			align-items: center;
			align-content: center;
			justify-content: center;

			.order-info-row {
				width: 100%;
				display: flex;
				align-content: center;
				justify-content: space-between;
				flex-direction: row;

				.equip {
					width: calc(100% - 50px);
					font-size: 13px;
					font-weight: 600;
					letter-spacing: 0.2px;
					text-transform: uppercase;

					span {
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}
				}

				.status,
				.protocol,
				.created {
					width: 33%;
					font-size: 12px;
					font-weight: 400;
					letter-spacing: 0.2px;
					display: flex;
					align-items: center;
					justify-content: flex-start;
				}

				.mobile-label {
					margin-right: 4px;
				}

				.options {
					width: 50px;
					.more-options-button-service-orders {
						width: 100%;
					}
				}
			}

			.order-info-row:first-child {
				height: 45px;
			}

			.order-info-row:last-child {
				padding-right: 30px;
			}
		}
	}

	@media screen and (max-width: 950px) {
		.list-item.web .order-info {
			.equip {
				width: 45%;
			}

			.status,
			.created,
			.protocol {
				width: calc(55% / 3);
			}
		}
	}

	@media screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
		.list-item.mobile {
			height: auto;
			position: relative;

			.order-info {
				.order-info-row {
					height: 25px !important;

					.equip {
						span {
							-webkit-line-clamp: 1;
						}
					}
				}

				.order-info-row:last-child {
					flex-direction: row-reverse;
				}

				.options {
					position: absolute;
					top: 17.5px;
					right: 20px;
				}
			}
		}
	}

	@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
		/* For portrait layouts only */
		.list-item.web {
			.order-info {
				.options {
					display: flex !important;
				}
			}
		}
	}

	// @media screen and (min-width: 901px) {
	// 	.list-item.mobile {
	// 		display: none;
	// 	}
	// }

	// @media screen and (max-width: 900px) {
	// 	.list-item.web {
	// 		display: none;
	// 	}
	// }

	@media screen and (max-width: 560px) {
		.list-item.mobile {
			height: 110px;

			.order-info-row:last-child {
				height: 50px;
				flex-wrap: wrap;

				.status {
					width: 100% !important;
					margin-top: 5px;
				}
			}
		}
	}
}

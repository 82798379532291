.custom-form-item-content {
	width: 100%;
	position: relative;

	.custom-form-item-input {
		width: 100%;
		position: relative;

		input {
			height: 45px;
			width: 100%;
		}

		.toggle-input div {
			top: -2px !important;
			right: 0px !important;
		}

		svg {
			position: absolute;
			top: 13px;
			right: 10px;
			z-index: 5;
		}
	}

	.custom-form-item-input.input-has-error {
		input {
			border: 1px solid;
		}
	}

	.custom-form-item-input.input-has-error.newPassword,
	.custom-form-item-input.input-has-error.confirmPassword {
		svg {
			display: none;
		}

		.toggle-input div {
			svg {
				display: block;
			}
		}
	}

	.custom-form-item-input.input-has-error.newPassword.required-pass,
	.custom-form-item-input.input-has-error.confirmPassword.required-pass {
		svg {
			display: block !important;
		}
	}

	.form-item-content-validator.email-input {
		height: 16px;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		.validator-link {
			font-size: 10px;
			font-weight: 400;
			letter-spacing: 0.18px;
			text-decoration: underline;
			color: inherit;
			cursor: pointer;
		}
	}

	.custom-form-item-content-validator {
		position: absolute;
		top: 0px;
		width: 100%;
		height: 100%;
		border: none;
		border-radius: 6px;
		padding: 10px;
		border: 1px solid;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		span {
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
			resize: none;
		}
	}
}

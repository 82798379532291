.create-account-form {
	width: 100%;
	height: auto;
	position: relative;

	.create-account-options .MuiTypography-body1 {
		font-size: 10px !important;
	}

	.main-form-content {
		.form-row-content {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-direction: row;

			.form-item-content {
				width: 100%;
				margin-bottom: 5px;
				label {
					font-size: 12px;
					font-weight: 400;
					letter-spacing: 0px;
					margin-bottom: 8px;
				}
				input {
					height: 45px;
					font-weight: 400;
				}
			}

			.form-item-content:first-child {
				margin-right: 20px;
			}
		}
	}

	.loading-main-content {
		width: calc(100% + 20px);
		height: calc(100% + 20px);
		position: absolute;
		margin: -10px;
		z-index: 10;
	}

	.create-account-options {
		margin-top: 10px;
		height: 55px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		span {
			font-size: 10px;
			font-weight: 400;
			letter-spacing: 0.18px;
		}

		.MuiFormGroup-root,
		.MuiFormControl-root {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
		}

		.MuiFormControlLabel-root {
			width: calc(100% - 16px);
		}

		.MuiTypography-body1 {
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
			font-family: 'Roboto', sans-serif;
			opacity: 1;
		}
	}

	.create-account-options.info {
		margin-top: 5px;
		height: 50px;
	}

	.create-account-form-actions {
		margin-top: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;

		button {
			width: calc(50% - 20px);
			height: 36px;
			border-radius: 6px;
			border: none;
			letter-spacing: 0.2px;
			font-size: 13px;
			font-weight: 500;
			cursor: pointer;
		}

		.button:focus-visible {
			outline: none;
			box-shadow: none;
		}
	}

	.reset-pass-completed {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
}

@media screen and (max-width: 900px) {
	.create-account-form {
		height: auto;

		.main-form-content {
			.form-row-content {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				flex-direction: column;

				.form-item-content {
					margin-right: 0px !important;
				}
			}
		}
	}

	.create-account-options.info {
		text-align: justify;
	}
}

.stores-settings-mobile-list-content {
	.stores-settings-mobile-list-items {
		.stores-settings-mobile-list-item {
			width: 100%;
			padding: 10px 20px;
			display: flex;
			align-content: stretch;
			justify-content: stretch;
			flex-direction: row;
			cursor: pointer;

			.stores-settings-mobile-info {
				width: 80%;
				display: flex;
				align-content: flex-start;
				justify-content: flex-start;
				flex-direction: column;

				.stores-settings-name,
				.stores-settings-info {
					width: 100%;
				}

				.stores-settings-name {
					margin-bottom: 8px;
					margin-left: -7px;
				}

				.stores-settings-info {
					display: flex;
					align-content: flex-start;
					justify-content: flex-start;
					flex-direction: column;

					.full-name {
						margin-bottom: 5px;
					}

					.users {
						height: 30px;
					}
				}

				div {
					font-size: 12px;
					font-weight: 400;
					letter-spacing: 0.18px;
					text-transform: capitalize;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;

					span {
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}

					.MuiFormGroup-root,
					.MuiFormControl-root {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
						flex-direction: row;
					}

					.MuiFormControlLabel-root {
						width: 100%;
						margin: 0px;

						.PrivateSwitchBase-root-5 {
							padding: 5px;
							margin-right: 5px;
						}
					}

					.MuiTypography-body1 {
						font-size: 13px;
						font-weight: 500;
						letter-spacing: 0.18px;
						opacity: 1;
					}
				}
			}

			.stores-settings-mobile-equip {
				width: 20%;
				display: flex;
				justify-content: center;
				align-items: center;
				span {
					font-size: 12px;
					font-weight: 400;
					letter-spacing: 0.18px;
				}
			}
		}
	}
}

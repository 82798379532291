.service-order-web-info-content {
	height: 100%;
	padding: 0px 20px;
	border-radius: 0px 35px 35px 0px;
	box-shadow: 5px 0px 10px #00000029;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;
	z-index: 1;

	.service-order-equip {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-direction: column;
		margin-bottom: 60px;

		h1 {
			font-size: 40px;
			font-weight: 600;
			letter-spacing: 0.6px;
			line-height: 55px;
			margin: 0 0 20px;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		b,
		span {
			text-align: left;
			font-size: 16px;
			letter-spacing: 0.24px;
		}

		b {
			font-weight: 600;
		}

		span {
			font-weight: 400;
		}

		.service-order-equip-files {
			margin-top: 20px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-direction: row;
			height: 31px;
			cursor: pointer;

			span {
				font-size: 14px;
				font-weight: 500;
			}

			svg {
				width: 23px;
				height: 23px;
				margin-right: 7px;
			}
		}
	}

	.service-order-info,
	.service-order-info-extra {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.service-order-info-location,
	.service-order-info-protocol,
	.service-order-info-visit,
	.service-order-info-tech-name,
	.service-order-info-tech-contact {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		margin-bottom: 20px;
		text-transform: capitalize;

		b,
		span {
			text-align: left;
			font-size: 16px;
			letter-spacing: 0.24px;
		}

		b {
			font-weight: 600;
		}

		span {
			font-weight: 500;
		}
	}

	.service-order-info-location {
		width: 100%;
	}

	.service-order-info-protocol,
	.service-order-info-visit,
	.service-order-info-tech-name,
	.service-order-info-tech-contact {
		width: 50%;
	}

	@media screen and (max-height: 880px) {
		.service-order-info-name {
			margin-bottom: 45px;
		}
	}

	@media screen and (max-height: 800px) {
		.service-order-info-name {
			margin-bottom: 20px;
		}
	}
}

@media screen and (max-width: 1600px) {
	.service-order-web-info-content {
		.service-order-equip {
			h1 {
				font-size: 36px;
				line-height: 40px;
			}

			b,
			span {
				font-size: 15px;
			}
		}

		.service-order-info-location,
		.service-order-info-warrant,
		.service-order-open-service {
			b,
			span {
				font-size: 15px;
			}

			.briefcase-icon {
				height: 23px;
				width: 23px;
			}
		}
	}
}

@media screen and (max-width: 1400px) {
	.service-order-web-info-content {
		.service-order-equip {
			h1 {
				font-size: 33px;
				line-height: 36px;
			}

			b,
			span {
				font-size: 15px;
			}
		}

		.service-order-info-location,
		.service-order-info-warrant,
		.service-order-open-service {
			b,
			span {
				font-size: 15px;
			}

			.briefcase-icon {
				height: 23px;
				width: 23px;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.service-order-web-info-content {
		.service-order-equip {
			h1 {
				font-size: 28px;
				line-height: 32px;
			}

			b,
			span {
				font-size: 14px;
			}
		}

		.service-order-info-location,
		.service-order-info-warrant,
		.service-order-open-service {
			b,
			span {
				font-size: 14px;
			}

			.briefcase-icon {
				height: 22px;
				width: 22px;
			}
		}
	}
}

@media screen and (max-width: 900px) {
	.service-order-web-info-content {
		.modal-main-backdrop {
			top: 0px !important;
		}
	}
}

.response-modal-web,
.response-modal-mobile {
	position: relative;
	background-color: inherit;

	.modal-main-content {
		height: auto;
		padding: 20px;
		border-radius: 6px;
		width: auto;
	}

	.loading-main-content {
		width: 320px;
		height: 100%;
		position: relative;
		top: 0;

		.loading-main {
			width: 100%;
			height: 100px;

			span {
				margin-top: 15px;
			}
		}
	}
}

.equipment-details-content {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: row;
	overflow: hidden;
	// position: relative;

	.equip-web-info-content {
		width: 600px;
	}

	.equip-service-orders-web-content {
		padding: 20px 0;
		width: calc(100% - 600px);
	}

	.loading-main-content {
		height: calc(100vh - 100px);
		top: 100px;
	}
}

@media screen and (max-width: 1600px) {
	.equipment-details-content {
		.equip-web-info-content {
			width: 500px;
		}

		.equip-service-orders-web-content {
			width: calc(100% - 500px);
		}
	}
}

@media screen and (max-width: 1400px) {
	.equipment-details-content {
		.equip-web-info-content {
			width: 400px;
		}

		.equip-service-orders-web-content {
			width: calc(100% - 400px);
		}
	}
}

@media screen and (max-width: 1200px) {
	.equipment-details-content {
		.equip-web-info-content {
			width: 300px;
		}

		.equip-service-orders-web-content {
			width: calc(100% - 300px);
		}
	}
}

@media screen and (max-width: 900px) {
	.equipment-details-content {
		position: unset;
		flex-direction: column;
		padding: 0px 0px 15px;

		.loading-main-content {
			height: calc(100vh - 55px);
			top: 0;
		}
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.equipment-details-content {
		overflow-x: hidden;
		overflow-y: auto;
		overflow: auto;
		height: 100%;
		padding: 0px;
	}
}

@media only screen and (device-width: 768px) {
	/* default iPad screens */
	.equipment-details-content {
		overflow-x: hidden;
		overflow-y: auto;
		overflow: auto;
		height: 100%;
		padding: 0px;
	}
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.equipment-details-content {
		flex-direction: column;
	}
}

.equip-web-info-content {
	height: 100%;
	padding: 0px 20px;
	border-radius: 0px 35px 35px 0px;
	box-shadow: 5px 0px 10px #00000029;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;
	z-index: 2;

	.equip-info-name {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-direction: column;
		margin-bottom: 60px;

		h1 {
			font-size: 40px;
			font-weight: 600;
			letter-spacing: 0.6px;
			line-height: 55px;
			margin: 0 0 20px;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		b,
		span {
			text-align: left;
			font-size: 16px;
			letter-spacing: 0.24px;
		}

		b {
			font-weight: 600;
		}

		span {
			font-weight: 400;
		}
	}

	.equip-info-extra {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
	}

	.equip-info-location,
	.equip-info-warrant {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		margin-bottom: 20px;
		text-transform: capitalize;

		b,
		span {
			text-align: left;
			font-size: 16px;
			letter-spacing: 0.24px;
		}

		b {
			font-weight: 600;
		}

		span {
			font-weight: 500;
		}
	}

	.equip-open-service {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		margin-top: 10px;
		margin-bottom: 20px;
		cursor: pointer;

		span {
			font-weight: 500;
			text-align: left;
			font-size: 16px;
			letter-spacing: 0.24px;
		}

		.briefcase-icon {
			margin-right: 5px;
			height: 24px;
			width: 24px;
		}
	}

	@media screen and (max-height: 880px) {
		.equip-info-name {
			margin-bottom: 45px;
		}
	}

	@media screen and (max-height: 800px) {
		.equip-info-name {
			margin-bottom: 20px;
		}
	}
}

@media screen and (max-width: 1400px) {
	.equip-web-info-content {
		.equip-info-name {
			h1 {
				font-size: 32px;
				line-height: 40px;
			}

			b,
			span {
				font-size: 15px;
			}
		}

		.equip-info-location,
		.equip-info-warrant,
		.equip-open-service {
			b,
			span {
				font-size: 15px;
			}

			.briefcase-icon {
				height: 23px;
				width: 23px;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.equip-web-info-content {
		.equip-info-name {
			h1 {
				font-size: 26px;
				line-height: 33px;
			}

			b,
			span {
				font-size: 14px;
			}
		}

		.equip-info-location,
		.equip-info-warrant,
		.equip-open-service {
			b,
			span {
				font-size: 14px;
			}

			.briefcase-icon {
				height: 22px;
				width: 22px;
			}
		}
	}
}

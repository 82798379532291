.notify-center-mobile-content {
	position: absolute;
	width: 100vw;
	height: calc(var(--app-height) - 48px);
	z-index: 50;

	.notify-center-header {
		width: 100%;
		height: 64px;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.mobile-navigator-component {
			width: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.notify-center-title {
			width: 120px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			text-transform: uppercase;
		}

		.notify-center-search {
			width: calc(100% - 190px);
			margin-right: 20px;
		}
	}

	.notify-center-content {
		width: 100%;
		height: calc(100% - 64px);
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
		padding-bottom: 15px;

		.loading-main-content,
		.empty-main-content {
			height: 100%;
		}
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.orders-main-content {
		padding: 0px 0px 20px;
		.orders-header-content {
			.options-list-area {
				width: 100%;
				.orders-status-label {
					display: none;
				}

				.list-options {
					width: 100%;

					.input-search-field {
						min-width: 120px;
						margin-right: 10px;
					}

					.filter-option {
						margin-right: 10px;
					}

					.dropdown-content {
						.MuiSelect-selectMenu {
							width: 26px;
						}

						.MuiSelect-select.MuiSelect-select {
							padding-right: 10px;
						}
					}
				}
			}
		}
	}
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.orders-main-content {
		padding: 0px 0px 20px;

		.orders-header-content {
			.options-list-area {
				width: 100%;

				.orders-status-label {
					display: none;
				}

				.list-options {
					width: 100%;

					.input-search-field {
						min-width: 165px;
						margin-right: 20px;
					}

					.filter-option {
						width: 30px;
					}

					.dropdown-content {
						width: 180px;

						.MuiSelect-selectMenu {
							width: 75px;
						}

						.MuiSelect-select.MuiSelect-select {
							padding-right: 15px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
	/* For portrait layouts only */
}

.service-order-timeline-description-content {
	.date-content {
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 0.21px;
	}

	.description-content {
		padding-bottom: 10px;

		span {
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 0.21px;
			margin-bottom: 3px;
		}
		p {
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 0.21px;
			margin-bottom: 3px;
		}

		b {
			font-weight: 600;
		}

		.visit-description {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: column;
		}

		.att-information {
			span {
				font-weight: 400;
			}

			p {
				font-weight: 400;
			}

			b {
				font-weight: 500;
			}
		}
	}
}

@media screen and (max-width: 900px) {
	.service-order-timeline-description-content {
		.date-content {
			font-size: 12px;
			letter-spacing: 0.18px;
		}

		.description-content {
			span {
				font-size: 12px;
				letter-spacing: 0.18px;
			}
		}
	}
}

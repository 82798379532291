.notifications-list-items {
	.notifications-list-item {
		width: 100%;
		height: 35px;
		padding: 0px 20px;
		display: flex;
		align-content: center;
		justify-content: space-between;
		flex-direction: row;
		cursor: pointer;

		.notifications-info {
			width: calc(100% - 25px);
			display: flex;
			align-content: center;
			justify-content: flex-start;
			flex-direction: row;

			div {
				height: 100%;
				display: flex;
				align-items: center;
				text-align: left;
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.18px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;

				span {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			.title {
				width: 350px;
			}

			.message {
				width: calc(100% - 470px);
			}

			.protocol,
			.created,
			.status {
				width: 120px;
			}
		}

		.options {
			width: 25px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.MuiSvgIcon-root {
				width: 20px;
				height: 20px;
			}
		}

		.options:hover {
			opacity: 0.8;
		}
	}

	.notifications-list-item.mark-as {
		.notifications-info {
			div {
				font-weight: 500;
			}
		}
	}

	@media screen and (max-width: 1300px) {
		.notifications-list-item {
			.notifications-info {
				.title {
					width: 20%;
				}

				.message {
					width: calc(80% - 140px);
				}

				.protocol,
				.created,
				.status {
					width: 140px;
				}
			}
		}
	}
}

#notifications-list-item-options {
	.MuiMenu-paper {
		width: 75px !important;
		text-align: right;
	}

	.MuiList-root {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
	}

	.MuiMenuItem-root {
		font-family: 'Roboto', sans-serif;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.18px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.MuiTouchRipple-root {
		display: none;
	}
}

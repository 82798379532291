.response-modal-web,
.response-modal-mobile {
	position: relative;
	background-color: inherit;

	.modal-main-content {
		height: auto;
		padding: 20px;
		border-radius: 6px;
		width: 480px;
		text-align: center;
		font-size: 13px;
	}

	.loading-main-content {
		width: 100%;
		height: 100%;
		top: 0;

		.loading-main {
			width: 100%;
			height: 100px;

			span {
				margin-top: 15px;
			}
		}
	}
}

.response-modal-web {
	.edit-account-response-modal {
		width: 480px;
		text-align: center;

		span {
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 0.2px;

			b {
				display: block;
				font-weight: 400;
			}
		}
	}
}

.response-modal-mobile {
	.edit-account-response-modal {
		width: 100%;
		text-align: center;

		span {
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.21px;

			b {
				font-weight: 400;
			}
		}
	}

	.loading-main-content {
		.loading-main {
			width: 100%;
			height: 100px;

			span {
				margin-top: 20px;
			}
		}
	}

	@media screen and (max-width: 480px) {
		.modal-main-content {
			max-width: 100%;
			.service-order-cancel-error-menssage {
				max-width: 100%;
			}
		}
	}
}

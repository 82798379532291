.paginator-limit-component {
	position: relative;
	.MuiSelect-select.MuiSelect-select {
		cursor: pointer;
	}

	.MuiSelect-root {
		height: 11px;
		white-space: break-spaces;
		overflow-y: hidden;
		line-height: 25px;
		width: 17px;
		margin-top: 1px;
		padding: 0px 0px 10px 5px;
		text-overflow: unset !important;
	}

	.MuiSelect-icon {
		top: 1px;
		right: 2px;
	}
}

@media screen and (max-width: 900px) {
	.paginator-limit-component {
		.MuiInputBase-root {
			width: 100%;
		}

		.MuiSelect-root {
			padding: 0px 0px 10px 0px;
		}

		.MuiSelect-icon {
			top: 2px;
			right: -4px;
		}
	}
}

@media screen and (max-width: 420px) {
	.paginator-limit-component {
		.MuiSelect-icon {
			top: 0px;
			right: -4px;
		}
	}
}

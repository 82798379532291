.notifications-list-content {
	.notifications-list-header {
		display: flex;
		align-content: center;
		justify-content: flex-start;
		flex-direction: row;
		width: calc(100% - 25px);
		height: 30px;
		padding: 0px 20px;

		.list-header {
			display: flex;
			align-content: center;
			justify-content: flex-start;
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 0.2px;

			.MuiSvgIcon-root {
				width: 15px;
				height: 15px;
				margin-top: 2px;
				margin-left: 5px;
				cursor: pointer;
			}
		}

		.title {
			width: 350px;
		}

		.message {
			width: calc(100% - 470px);
		}

		.protocol,
		.created,
		.status {
			width: 120px;
		}
	}
}

@media screen and (max-width: 1300px) {
	.notifications-list-content {
		.notifications-list-header {
			.title {
				width: 20%;
			}

			.message {
				width: calc(80% - 140px);
			}

			.protocol,
			.created,
			.status {
				width: 140px;
			}
		}
	}
}

.stores-settings-main-content {
	width: 100%;
	height: 100%;
	padding: 20px 0;
	position: relative;

	.has-error-content,
	.loading-main-content {
		position: absolute;
		z-index: 30;
		top: 60px;
		left: 0;
		height: calc(100% - 60px);
	}

	.empty-main-content {
		height: calc(100% - 80px);
		top: 80px;
	}

	.list-view {
		height: calc(100% - 40px);
		width: 100%;
		overflow: auto;

		.stores-settings-list-content {
			height: calc(100% - 47px);
			overflow-y: hidden;
			padding-top: 20px;

			.stores-settings-mobile-list-items {
				height: calc(100% - 30px);
				overflow-y: auto;
			}
		}
	}
}

@media only screen and (max-device-width: 480px) {
	/* styles for mobile browsers smaller than 480px; (iPhone) */
	.stores-settings-main-content {
		padding: 0px;
		.has-error-content,
		.loading-main-content {
			top: 125px;
			height: calc(100vh - 114px);
		}

		.empty-main-content {
			top: 165px;
			height: calc(100vh - 175px);
		}
	}
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.stores-settings-main-content {
		padding: 0px;

		.list-view {
			height: calc(100% - 64px);
			overflow: auto;
			.stores-settings-list-content {
				height: 100%;
				overflow-y: hidden;

				.stores-settings-mobile-list-items {
					height: 100%;
					overflow-y: hidden;
				}
			}

			.paginator-main-content {
				margin: 20px 0;
			}
		}

		.has-error-content,
		.loading-main-content {
			top: 125px;
			height: calc(100vh - 114px);
		}

		.empty-main-content {
			top: 165px;
			height: calc(100vh - 175px);
		}
	}
}

@media screen and (max-height: 1024px) and (max-width: 768px) {
	.list-view .paginator-main-content {
		margin-bottom: 10%;
	}
}

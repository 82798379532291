.mobile-app-settings-main-content {
	.mobile-app-settings-item {
		height: 70px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		border-bottom: 2px solid;
		padding: 0px 20px;

		label {
			margin: 0px;
		}
	}
}

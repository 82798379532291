.stores-settings-mobile-list-content {
	width: 100%;

	.stores-settings-mobile-search-content {
		position: fixed;
		padding: 20px;
		height: 80px;
		width: 100%;
		z-index: 3;

		.page-list-options-content {
			background-color: inherit;
			width: 100%;
			margin: 0;
			z-index: 10;
		}
	}

	.stores-settings-mobile-list-options {
		width: 100%;
		padding: 90px 20px 0px;
		margin-bottom: 10px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;

		.stores-view-type {
			.MuiFormGroup-root {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				flex-direction: row;
			}

			.MuiFormControlLabel-root:first-child {
				margin: 0px 15px 0px 0px;
			}

			.MuiFormControlLabel-root {
				min-width: 50px;
			}

			.MuiFormControl-root {
				padding: 0px;

				.MuiIconButton-root,
				.PrivateSwitchBase-root-5 {
					padding: 0px;
				}
			}

			.MuiIconButton-label {
				div {
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 100%;
					border: 1px solid;

					.MuiSvgIcon-root:first-child {
						width: 16px;
						height: 16px;
						padding: 0px;
						opacity: 0;
					}
				}
			}

			.Mui-checked {
				.MuiIconButton-label {
					div {
						.MuiSvgIcon-root:last-child {
							transform: scale(1.3);
							left: unset;
						}
					}
				}
			}

			.MuiFormControlLabel-root {
				height: 40px;
				margin: 0px;
			}

			.MuiTypography-body1 {
				text-align: left;
				font-size: 12px;
				letter-spacing: 0.18px;
				text-transform: capitalize;
				font-family: Roboto, sans-serif;
				font-weight: 400;
				margin-left: 5px;
			}
		}
	}
}

.stores-settings-mobile-header-content {
	width: 100%;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;

	.mobile-navigator-component {
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.stores-settings-center-title {
		width: 180px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-transform: uppercase;
	}
}

.response-modal-web,
.response-modal-mobile {
	position: relative;
	background-color: inherit;

	.modal-main-content {
		height: auto;
		padding: 20px;
		border-radius: 6px;
		width: auto;
	}

	.loading-main-content {
		width: 320px;
		height: 120px !important;
		position: relative;
		top: 0 !important;

		.loading-main {
			width: 100%;
			height: 100px;

			span {
				margin-top: 15px;
			}
		}
	}
}

.response-modal-web {
	.service-order-success-response-modal {
		width: 480px;
		text-align: center;
		max-width: 100%;

		span {
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 0.2px;

			b {
				display: block;
				font-weight: 500;
				font-size: 14px;
			}
		}
	}
}

.response-modal-mobile {
	.service-order-success-response-modal {
		width: 100%;
		text-align: center;

		span {
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.21px;

			b {
				font-weight: 500;
			}
		}
	}

	.loading-main-content {
		.loading-main {
			width: 100%;
			height: 100px;

			span {
				margin-top: 20px;
			}
		}
	}
}

@media screen and (min-width: 480px) and (min-height: 980px) and (max-width: 900px) and (max-height: 1280px) and (orientation: portrait) {
	.open-service-order-content {
		width: calc(100% - 20px);
	}
}

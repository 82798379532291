.response-modal-web {
	box-shadow: 0px 0px 6px #000000;
	border-radius: 6px;

	.modal-header-content {
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px 6px 0px 0px;
		position: relative;

		span {
			text-align: center;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0.24px;
			text-transform: uppercase;
		}

		.modal-header-close-content {
			position: absolute;
			width: 20px;
			height: 20px;
			border-radius: 20px;
			top: 15px;
			right: 15px;
			cursor: pointer;

			display: flex;
			align-items: center;
			justify-content: center;

			.MuiSvgIcon-root {
				width: 16px;
				height: 16px;
			}
		}
	}

	.modal-main-content {
		width: 100%;
		height: auto;
		padding: 20px;
	}

	.modal-action-content {
		display: none;
	}
}

.response-modal-mobile {
	box-shadow: 0px 0px 6px #000000;
	border-radius: 10px;
	max-width: 310px;
	position: relative;

	.modal-header-image {
		width: 100%;
		height: 75px;
		position: relative;

		img {
			width: 100%;
			height: 100%;
		}

		.modal-header-close-content {
			position: absolute;
			width: 17px;
			height: 17px;
			border-radius: 20px;
			top: 7px;
			right: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;

			.MuiSvgIcon-root {
				width: 15px;
				height: 15px;
			}
		}
	}

	.modal-main-content {
		width: 100%;
		height: auto;
		padding: 0px 10px 20px !important;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		span {
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.21px;
		}
	}

	.modal-action-content {
		width: 100%;
		height: 25px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		button {
			width: 50px;
			height: 25px;
			border: none;
			text-transform: uppercase;
			text-align: center;
			border-radius: 4px;
			text-align: center;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
		}
	}
}

.service-order-details-content {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: row;
	position: relative;

	.service-order-web-info-content {
		width: 600px;
	}

	.service-order-timeline-content {
		width: calc(100% - 600px);
	}

	.loading-main-content {
		z-index: 2;
	}
}

@media screen and (max-width: 1600px) {
	.service-order-details-content {
		.service-order-web-info-content {
			width: 500px;
		}

		.service-order-timeline-content {
			width: calc(100% - 500px);
		}
	}
}

@media screen and (max-width: 1400px) {
	.service-order-details-content {
		.service-order-web-info-content {
			width: 400px;
		}

		.service-order-timeline-content {
			width: calc(100% - 400px);
		}
	}
}

@media screen and (max-width: 1200px) {
	.service-order-details-content {
		.service-order-web-info-content {
			width: 350px;
		}

		.service-order-timeline-content {
			width: calc(100% - 350px);
		}
	}
}

@media screen and (max-width: 900px) {
	.service-order-details-content {
		position: unset;
		flex-direction: column;
		padding: 0px 0px 0px;
	}
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	/* For portrait layouts only */
	.service-order-details-content {
		flex-direction: column;
	}
}

.equip-details-open-service-loading {
	.loading-main-content {
		width: 320px;
		height: 120px;
		position: relative;
		top: unset;
		left: unset;

		.loading-main {
			span {
				margin-top: 30px;
			}
		}
	}
}

@media screen and (max-width: 480px) and (max-height: 980px) and (orientation: portrait) {
	.equip-details-open-service-loading {
		.loading-main-content {
			width: 100%;
			height: calc(100% - 50px);
			position: absolute;
			top: 50px;
			left: 0;
		}
	}
}

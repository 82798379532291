.service-order-mobile-info-content {
	width: 100%;
	z-index: 1;

	.service-order-info-extra {
		width: 100%;
		padding: 10px 20px;

		.service-order-info-visit,
		.service-order-info-protocol,
		.service-order-info-location,
		.service-order-info-tech-name {
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: column;
			margin-bottom: 5px;

			.location-item {
				width: 100%;
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				flex-direction: row;

				.location-item-label {
					width: 75px;
				}

				.location-item-value {
					width: calc(100% - 75px);
				}
			}

			b {
				font-weight: 500;
				font-size: 12px;
			}

			span {
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.18px;
				text-transform: capitalize;
			}
		}

		.service-order-info-visit,
		.service-order-info-protocol,
		.service-order-info-tech-name {
			flex-direction: row;
			align-items: center;

			b {
				margin-right: 5px;
			}
		}

		.service-order-equip-files {
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-direction: row;
			height: 31px;
			cursor: pointer;

			span {
				font-size: 12px;
				font-weight: 400;
			}

			svg {
				width: 23px;
				height: 23px;
				margin-right: 7px;
			}
		}
	}

	.service-order-contact {
		display: flex;
		align-items: center;
		justify-content: center;

		.contact-area {
			width: 190px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			margin-bottom: 10px;

			.call-app-button,
			.whats-app-button {
				width: 85px;
				height: 30px;
				margin: 0px 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: row;
				border-radius: 6px;

				.MuiSvgIcon-root {
					width: 16px;
					height: 16px;
					margin-right: 4px;
				}

				span {
					font-size: 12px;
					font-weight: 400;
					letter-spacing: 0.18px;
				}
			}

			.whats-app-button {
				border: 1px solid;
			}
		}
	}
}

@media screen and (max-width: 900px) {
	.service-order-mobile-info-content {
		.modal-main-backdrop {
			top: 0px !important;
		}
	}
}
